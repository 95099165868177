import React, { useState } from "react";
import {
    Box,
    Stepper,
    Step,
    StepLabel,
    Typography,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Grid,
    List, ListItem, ListItemText,
    option,
} from "@mui/material";
import "./Stepperformdup.css";
import TextField from '@mui/material/TextField';
import FileCopyIcon from '@mui/icons-material/FileCopy'; // Import the copy icon
import { useNavigate } from 'react-router-dom';
import logoimage from './Asset/New_Assert/ESO_Logo.png'
import FastForwardIcon from '@mui/icons-material/FastForward';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { Link } from 'react-router-dom';

const currencies = [
    {
        value: 'USD',
        label: '$',
    },
    {
        value: 'EUR',
        label: '€',
    },
    {
        value: 'BTC',
        label: '฿',
    },
    {
        value: 'JPY',
        label: '¥',
    },
];
const StepperFormDup = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [formData, setFormData] = useState({
        country: "",
        state: "",
        city: "",
    });
    const [formErrors, setFormErrors] = React.useState({
        websiteUrl: "",
    });
    const navigate = useNavigate();

    const steps = [
        "USER DATA",
        "DOMAIN DATA",
        "DOMAIN OWNERSHIP VERIFICATION",
        "GOOGLE ANALYTICS",
    ];

    const handleNext = () => {
        setActiveStep((prevStep) => prevStep + 1);
    };
    const handleNextRedirect = () => {
        navigate('/');
    };
    const handleBack = () => {
        setActiveStep((prevStep) => prevStep - 1);
    };

    const handleChange = (field) => (event) => {
        setFormData((prev) => ({
            ...prev,
            [field]: event.target.value,
        }));
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [field]: "", // Clear error on change
        }));
    };

    return (
        <div className="stepper-container" >
            <header className="stepper-header">
                <img className="Eso_logo" src={logoimage} alt="logo" style={{ width: "14%", background: "#fff" }} />
                <Link to="/"> <PowerSettingsNewIcon /></Link>

            </header>
            <Box sx={{ width: "100%", fontFamily: "Poppins, sans-serif" }}>
                {/* Custom Stepper */}

                <Box
                    className="stepperFormclass"
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "82px",
                        flexWrap: "wrap", // Wrap items on smaller screens
                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                        padding: "40px 0px"
                    }}
                >
                    {steps.map((label, index) => (
                        <React.Fragment key={index}>
                            {/* Step labels */}
                            <Box
                                sx={(theme) => ({
                                    padding: "1px 32px",
                                    backgroundColor: activeStep >= index ? "#FF601F" : "white", // Active steps (current and previous) in orange
                                    color: activeStep >= index ? "white" : "black", // Text color for active (white) and inactive (black)
                                    borderRadius: "20px",
                                    fontWeight: activeStep >= index ? "bold" : "normal", // Bold for active steps
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    margin: "0 8px",
                                    cursor: "pointer",
                                    border: activeStep >= index ? "none" : "1px solid #ddd",
                                    wordWrap: "break-word",
                                    whiteSpace: "normal",
                                    textAlign: "center",
                                    height: "40px",
                                    zIndex: 1,
                                    // Responsive styles
                                    fontSize: "14px",
                                    [theme.breakpoints.down("sm")]: {
                                        padding: "1px 16px",
                                        fontSize: "12px",
                                        margin: "4px",
                                    },
                                })}
                            // onClick={() => setActiveStep(index)} // Uncomment if steps should be clickable
                            >
                                {label}
                            </Box>

                            {/* Dotted line between steps */}
                            {index < steps.length - 1 && (
                                <Box
                                    sx={(theme) => ({
                                        mx: 1,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        borderBottom: activeStep > index ? "3px solid #FF601F" : "3px dotted #ddd", // Solid line for completed steps
                                        width: "30px",
                                        height: "2px",
                                        zIndex: 0,
                                        marginLeft: "0px",
                                        marginRight: "0px",
                                        // Responsive styles
                                        [theme.breakpoints.down("sm")]: {
                                            width: "20px",
                                        },
                                    })}
                                />
                            )}
                        </React.Fragment>
                    ))}
                </Box>
                {/* Form Content */}
                <Box sx={{ mt: 3 }}>
                    {activeStep === 0 && (
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                            <Box sx={{ backgroundColor: 'white', borderRadius: '10px', padding: '40px 20px' }}>
                                <Typography variant="h6" sx={{ mb: 2, fontFamily: 'Space Grotesk' }} className="stepperLocation1">
                                    Please Share Your Location Details
                                </Typography>
                                <Typography variant="body2" sx={{ mb: 4, display: "flex", justifyContent: "space-around", fontSize: "1.2rem", fontFamily: 'Space Grotesk' }}>
                                    This Helps Us Customize Your Experience
                                </Typography>
                                <form>
                                    <Grid
                                        className="countrystepperform"
                                        container
                                        spacing={3}
                                        sx={{ display: "grid", placeItems: "center" }}
                                    >
                                        {/* <Grid item xs={12}>
                    <TextField
                      id="outlined-select-country"
                      select
                      label="Country"
                      value={formData.country}
                      onChange={handleChange("country")}
                      InputLabelProps={{ shrink: true }}
                      slotProps={{
                        select: {
                            native: true,
                        },
                    }}
                      sx={{
                        "& .MuiInputLabel-root": {
                          color: "#000000", // Label color for all states
                        },
                        "& .MuiInputLabel-root.Mui-focused": {
                          color: "#000000", // Prevents color change on focus
                        },
                        "& .MuiInputLabel-root:hover": {
                          color: "#000000", // Prevents color change on hover
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": { borderColor: "#FF601F" },
                          "&:hover fieldset": { borderColor: "#FF601F" },
                          "&.Mui-focused fieldset": { borderColor: "#FF601F" },
                        },
                        // position: 'relative',
                        // '&::after': {
                        //     content: '""',
                        //     position: 'absolute',
                        //     left: 61,
                        //     top: -3,
                        //     width: '7px',
                        //     height: '7px',
                        //     borderRadius: '50%',
                        //     backgroundColor: '#ff601f',
                        // },
                        // '&::before': {
                        //     content: '""',
                        //     position: 'absolute',
                        //     left: 5,
                        //     top: -3,
                        //     width: '7px',
                        //     height: '7px',
                        //     borderRadius: '50%',
                        //     backgroundColor: '#ff601f',
                        // },
                      }}
                    >
                      <MenuItem value="" disabled>
                        Please select a country
                      </MenuItem>
                      <MenuItem value="USA">USA</MenuItem>
                      <MenuItem value="Canada">Canada</MenuItem>
                    </TextField>
                  </Grid> */}

                                        <Grid item xs={12}>
                                            <InputLabel id="outlined-select-country">Country</InputLabel>
                                            <TextField
                                                id="outlined-select-country"
                                                select
                                                // label="Country"
                                                value={formData.country}
                                                onChange={handleChange("country")}
                                                // InputLabelProps={{ shrink: true }}
                                                sx={{
                                                    "& .MuiInputLabel-root": {
                                                        color: "#000000", // Label color for all states
                                                    },
                                                    "& .MuiInputLabel-root.Mui-focused": {
                                                        color: "#000000", // Prevents color change on focus
                                                    },
                                                    "& .MuiInputLabel-root:hover": {
                                                        color: "#000000", // Prevents color change on hover
                                                    },
                                                    "& .MuiOutlinedInput-root": {
                                                        "& fieldset": { borderColor: "#FF601F" },
                                                        "&:hover fieldset": { borderColor: "#FF601F" },
                                                        "&.Mui-focused fieldset": { borderColor: "#FF601F" },
                                                    },
                                                    //   '&::after': {
                                                    //     content: '""',
                                                    //     position: 'absolute',
                                                    //     left: 47,
                                                    //     top: -3,
                                                    //     width: '7px',
                                                    //     height: '7px',
                                                    //     borderRadius: '50%',
                                                    //     backgroundColor: '#ff601f',
                                                    // },
                                                    // '&::before': {
                                                    //     content: '""',
                                                    //     position: 'absolute',
                                                    //     left: 5,
                                                    //     top: -3,
                                                    //     width: '7px',
                                                    //     height: '7px',
                                                    //     borderRadius: '50%',
                                                    //     backgroundColor: '#ff601f',
                                                    // },
                                                }}
                                            >
                                                <MenuItem value="" disabled>
                                                    Select Here...
                                                </MenuItem>
                                                <MenuItem value="California">California</MenuItem>
                                                <MenuItem value="Texas">Texas</MenuItem>
                                            </TextField>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <InputLabel id="outlined-select-country">State</InputLabel>

                                            <TextField
                                                id="outlined-select-state"
                                                select
                                                // label="State"
                                                value={formData.state}
                                                // InputLabelProps={{ shrink: true }}
                                                onChange={handleChange("state")}
                                                sx={{
                                                    "& .MuiInputLabel-root": {
                                                        color: "#000000", // Label color for all states
                                                    },
                                                    "& .MuiInputLabel-root.Mui-focused": {
                                                        color: "#000000", // Prevents color change on focus
                                                    },
                                                    "& .MuiInputLabel-root:hover": {
                                                        color: "#000000", // Prevents color change on hover
                                                    },
                                                    "& .MuiOutlinedInput-root": {
                                                        "& fieldset": { borderColor: "#FF601F" },
                                                        "&:hover fieldset": { borderColor: "#FF601F" },
                                                        "&.Mui-focused fieldset": { borderColor: "#FF601F" },
                                                    },
                                                    //   '&::after': {
                                                    //     content: '""',
                                                    //     position: 'absolute',
                                                    //     left: 47,
                                                    //     top: -3,
                                                    //     width: '7px',
                                                    //     height: '7px',
                                                    //     borderRadius: '50%',
                                                    //     backgroundColor: '#ff601f',
                                                    // },
                                                    // '&::before': {
                                                    //     content: '""',
                                                    //     position: 'absolute',
                                                    //     left: 5,
                                                    //     top: -3,
                                                    //     width: '7px',
                                                    //     height: '7px',
                                                    //     borderRadius: '50%',
                                                    //     backgroundColor: '#ff601f',
                                                    // },
                                                }}
                                            >
                                                <MenuItem value="" disabled>
                                                    Select Here...
                                                </MenuItem>
                                                <MenuItem value="California">California</MenuItem>
                                                <MenuItem value="Texas">Texas</MenuItem>
                                            </TextField>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <InputLabel id="outlined-select-country">City</InputLabel>

                                            <TextField
                                                id="outlined-select-city"
                                                select
                                                // label="City"
                                                value={formData.city}
                                                onChange={handleChange("city")}
                                                // InputLabelProps={{ shrink: true }}
                                                sx={{
                                                    "& .MuiInputLabel-root": {
                                                        color: "#000000", // Label color for all states
                                                    },
                                                    "& .MuiInputLabel-root.Mui-focused": {
                                                        color: "#000000", // Prevents color change on focus
                                                    },
                                                    "& .MuiInputLabel-root:hover": {
                                                        color: "#000000", // Prevents color change on hover
                                                    },
                                                    "& .MuiOutlinedInput-root": {
                                                        "& fieldset": { borderColor: "#FF601F" },
                                                        "&:hover fieldset": { borderColor: "#FF601F" },
                                                        "&.Mui-focused fieldset": { borderColor: "#FF601F" },
                                                    },
                                                    //   '&::after': {
                                                    //     content: '""',
                                                    //     position: 'absolute',
                                                    //     left: 40,
                                                    //     top: -3,
                                                    //     width: '7px',
                                                    //     height: '7px',
                                                    //     borderRadius: '50%',
                                                    //     backgroundColor: '#ff601f',
                                                    // },
                                                    // '&::before': {
                                                    //     content: '""',
                                                    //     position: 'absolute',
                                                    //     left: 5,
                                                    //     top: -3,
                                                    //     width: '7px',
                                                    //     height: '7px',
                                                    //     borderRadius: '50%',
                                                    //     backgroundColor: '#ff601f',
                                                    // },
                                                }}
                                            >
                                                <MenuItem value="" disabled>
                                                    Select Here...
                                                </MenuItem>
                                                <MenuItem value="Los Angeles">Los Angeles</MenuItem>
                                                <MenuItem value="Houston">Houston</MenuItem>


                                            </TextField>
                                        </Grid>
                                    </Grid>
                                    <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
                                        <Button
                                            className="steppersubmitform"
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                            disabled={!formData.country || !formData.state || !formData.city}
                                            sx={{
                                                padding: "22px 63px",
                                                borderRadius: "11px",
                                                fontWeight: "900",
                                                fontSize: "16px",
                                                backgroundColor: !formData.country || !formData.state || !formData.city ? "#ccc" : "#ff601f",
                                                color: !formData.country || !formData.state || !formData.city ? "#666" : "white",
                                                "&:hover": {
                                                    backgroundColor: !formData.country || !formData.state || !formData.city ? "#ccc" : "#ff7d45",
                                                },
                                            }}
                                        >
                                            Next <FastForwardIcon />

                                        </Button>
                                    </Box>
                                </form>
                            </Box>
                        </Box>
                    )}

                    {/* Next steps */}
                    {activeStep === 1 && (
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                            <Box sx={{ backgroundColor: 'white', borderRadius: '10px', padding: '40px 20px' }}>
                                <Typography variant="h6" sx={{ mb: 2, fontFamily: 'Space Grotesk' }} className="stepperLocation1">
                                    Please Share Your Location Details
                                </Typography>
                                <Typography variant="body2" sx={{ mb: 4, display: "flex", justifyContent: "space-around", fontSize: "1.2rem", fontFamily: 'Space Grotesk' }}>
                                    This Helps Us Customize Your Experience
                                </Typography>
                                <form>
                                    <Grid className="countrystepperform" container spacing={3} sx={{ display: "grid", placeItems: "center" }}>
                                        <Grid item xs={12}>
                                            <InputLabel id="outlined-select-country">Website Name</InputLabel>

                                            <TextField
                                                id="outlined-website-name"
                                                // label="Website Name"
                                                type="text"
                                                // InputLabelProps={{ shrink: true }}
                                                placeholder="Enter your website name"
                                                value={formData.websiteName}
                                                onChange={handleChange("websiteName")}
                                                sx={{
                                                    "& .MuiInputLabel-root": { color: "#000000" },
                                                    "&:hover fieldset": { borderColor: "#FF601F" },
                                                    "& .MuiOutlinedInput-root": { "& fieldset": { borderColor: "#FF601F" }, "&.Mui-focused fieldset": { borderColor: "#FF601F" }, "&:hover fieldset": { borderColor: "#FF601F" } },
                                                    //   '&::after': {
                                                    //     content: '""',
                                                    //     position: 'absolute',
                                                    //     left: 100,
                                                    //     top: -3,
                                                    //     width: '7px',
                                                    //     height: '7px',
                                                    //     borderRadius: '50%',
                                                    //     backgroundColor: '#ff601f',
                                                    // },
                                                    // '&::before': {
                                                    //     content: '""',
                                                    //     position: 'absolute',
                                                    //     left: 5,
                                                    //     top: -3,
                                                    //     width: '7px',
                                                    //     height: '7px',
                                                    //     borderRadius: '50%',
                                                    //     backgroundColor: '#ff601f',
                                                    // },
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <InputLabel id="outlined-select-country">Website URL</InputLabel>

                                            <TextField
                                                id="outlined-website-url"
                                                // label="Website URL"
                                                type="url"
                                                // InputLabelProps={{ shrink: true }}
                                                placeholder="Enter your website URL"
                                                value={formData.websiteUrl}
                                                onChange={handleChange("websiteUrl")}
                                                onBlur={() => {
                                                    const urlPattern = new RegExp(
                                                        "^(https?:\\/\\/)?(([a-zA-Z0-9-]+)\\.)+[a-zA-Z]{2,}(\\/[^\\s]*)?$"
                                                    );
                                                    setFormErrors((prevErrors) => ({
                                                        ...prevErrors,
                                                        websiteUrl: !urlPattern.test(formData.websiteUrl) && formData.websiteUrl ? "Please enter a valid URL." : ""
                                                    }));
                                                }}
                                                error={!!formErrors.websiteUrl}
                                                helperText={formErrors.websiteUrl || ""}
                                                sx={{
                                                    "& .MuiInputLabel-root": { color: "#000000" },
                                                    "& .MuiOutlinedInput-root": { "& fieldset": { borderColor: "#FF601F" }, "&.Mui-focused fieldset": { borderColor: "#FF601F" }, "&:hover fieldset": { borderColor: "#FF601F" } },
                                                    //   '&::after': {
                                                    //     content: '""',
                                                    //     position: 'absolute',
                                                    //     left: 86,
                                                    //     top: -3,
                                                    //     width: '7px',
                                                    //     height: '7px',
                                                    //     borderRadius: '50%',
                                                    //     backgroundColor: '#ff601f',
                                                    // },
                                                    // '&::before': {
                                                    //     content: '""',
                                                    //     position: 'absolute',
                                                    //     left: 5,
                                                    //     top: -3,
                                                    //     width: '7px',
                                                    //     height: '7px',
                                                    //     borderRadius: '50%',
                                                    //     backgroundColor: '#ff601f',
                                                    // },
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <InputLabel id="outlined-select-country">Website Type</InputLabel>

                                            <TextField
                                                id="outlined-select-city"
                                                select
                                                // InputLabelProps={{ shrink: true }}
                                                // label="Website Type"
                                                value={formData.websitetype}
                                                onChange={handleChange("websitetype")}
                                                sx={{
                                                    "& .MuiInputLabel-root": { color: "#000000" },
                                                    "& .MuiOutlinedInput-root": { "& fieldset": { borderColor: "#FF601F" }, "&.Mui-focused fieldset": { borderColor: "#FF601F" }, "&:hover fieldset": { borderColor: "#FF601F" } },
                                                    //   '&::after': {
                                                    //     content: '""',
                                                    //     position: 'absolute',
                                                    //     left: 87,
                                                    //     top: -3,
                                                    //     width: '7px',
                                                    //     height: '7px',
                                                    //     borderRadius: '50%',
                                                    //     backgroundColor: '#ff601f',
                                                    // },
                                                    // '&::before': {
                                                    //     content: '""',
                                                    //     position: 'absolute',
                                                    //     left: 5,
                                                    //     top: -3,
                                                    //     width: '7px',
                                                    //     height: '7px',
                                                    //     borderRadius: '50%',
                                                    //     backgroundColor: '#ff601f',
                                                    // },
                                                }}
                                            >
                                                <MenuItem value="" disabled>Select Here...</MenuItem>
                                                <MenuItem value="Los Angeles">HTML</MenuItem>
                                                <MenuItem value="Houston">JS</MenuItem>
                                            </TextField>
                                        </Grid>

                                        {/* Dropdown for Authentication */}
                                        <Grid item xs={12}>
                                            <InputLabel id="outlined-select-country">Does your website need authentication to access?</InputLabel>

                                            <TextField
                                                id="authentication-dropdown"
                                                select
                                                // InputLabelProps={{ shrink: true }}
                                                // label="Does your website need authentication to access?"
                                                value={formData.requiresAuth}
                                                onChange={(event) =>
                                                    setFormData((prevData) => ({
                                                        ...prevData,
                                                        requiresAuth: event.target.value,
                                                        username: "", // Reset username if the dropdown changes
                                                        password: "", // Reset password if the dropdown changes
                                                    }))
                                                }
                                                sx={{
                                                    "& .MuiInputLabel-root": { color: "#000000" },
                                                    "& .MuiOutlinedInput-root": { "& fieldset": { borderColor: "#FF601F" }, "&.Mui-focused fieldset": { borderColor: "#FF601F" }, "&:hover fieldset": { borderColor: "#FF601F" } },
                                                    //   '&::after': {
                                                    //     content: '""',
                                                    //     position: 'absolute',
                                                    //     left: 290,
                                                    //     top: -3,
                                                    //     width: '7px',
                                                    //     height: '7px',
                                                    //     borderRadius: '50%',
                                                    //     backgroundColor: '#ff601f',
                                                    // },
                                                    // '&::before': {
                                                    //     content: '""',
                                                    //     position: 'absolute',
                                                    //     left: 5,
                                                    //     top: -3,
                                                    //     width: '7px',
                                                    //     height: '7px',
                                                    //     borderRadius: '50%',
                                                    //     backgroundColor: '#ff601f',
                                                    // },
                                                }}
                                            >
                                                <MenuItem value="" disabled>Select Here...</MenuItem>
                                                <MenuItem value="yes">Yes</MenuItem>
                                                <MenuItem value="no">No</MenuItem>
                                            </TextField>
                                        </Grid>

                                        {/* Conditional Input Fields for Username and Password */}
                                        {formData.requiresAuth === "yes" && (
                                            <>
                                                <Grid item xs={12} sx={{ mt: 2 }}>
                                                    <TextField
                                                        id="username"
                                                        label="Username"
                                                        value={formData.username}
                                                        onChange={(event) =>
                                                            setFormData((prevData) => ({ ...prevData, username: event.target.value }))
                                                        }
                                                        fullWidth
                                                        sx={{
                                                            "& .MuiInputLabel-root": { color: "#000000" },
                                                            "& .MuiOutlinedInput-root": { "& fieldset": { borderColor: "#FF601F" }, "&.Mui-focused fieldset": { borderColor: "#FF601F" } },
                                                            //   '&::after': {
                                                            //     content: '""',
                                                            //     position: 'absolute',
                                                            //     left: 75,
                                                            //     top: -3,
                                                            //     width: '7px',
                                                            //     height: '7px',
                                                            //     borderRadius: '50%',
                                                            //     backgroundColor: '#ff601f',
                                                            // },
                                                            // '&::before': {
                                                            //     content: '""',
                                                            //     position: 'absolute',
                                                            //     left: 7,
                                                            //     top: -3,
                                                            //     width: '7px',
                                                            //     height: '7px',
                                                            //     borderRadius: '50%',
                                                            //     backgroundColor: '#ff601f',
                                                            // },
                                                        }}
                                                    />
                                                </Grid>

                                                <Grid item xs={12} sx={{ mt: 2 }}>
                                                    <TextField
                                                        id="password"
                                                        label="Password"
                                                        type="password"
                                                        value={formData.password}
                                                        onChange={(event) =>
                                                            setFormData((prevData) => ({ ...prevData, password: event.target.value }))
                                                        }
                                                        fullWidth
                                                        sx={{
                                                            "& .MuiInputLabel-root": { color: "#000000" },
                                                            "& .MuiOutlinedInput-root": { "& fieldset": { borderColor: "#FF601F" }, "&.Mui-focused fieldset": { borderColor: "#FF601F" } },
                                                            //   '&::after': {
                                                            //     content: '""',
                                                            //     position: 'absolute',
                                                            //     left: 75,
                                                            //     top: -3,
                                                            //     width: '7px',
                                                            //     height: '7px',
                                                            //     borderRadius: '50%',
                                                            //     backgroundColor: '#ff601f',
                                                            // },
                                                            // '&::before': {
                                                            //     content: '""',
                                                            //     position: 'absolute',
                                                            //     left: 5,
                                                            //     top: -3,
                                                            //     width: '7px',
                                                            //     height: '7px',
                                                            //     borderRadius: '50%',
                                                            //     backgroundColor: '#ff601f',
                                                            // },
                                                        }}
                                                    />
                                                </Grid>
                                            </>
                                        )}
                                    </Grid>

                                    {/* Buttons */}
                                    <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleBack}
                                            sx={{
                                                padding: "22px 63px",
                                                borderRadius: "11px",
                                                fontWeight: "900",
                                                fontSize: "16px",
                                                backgroundColor: "#ff601f",
                                                color: "white",
                                                "&:hover": { backgroundColor: "#ff7d45" },
                                            }}
                                        >
                                            Back
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                            disabled={
                                                !formData.websiteName ||
                                                !formData.websiteUrl ||
                                                !formData.websitetype ||
                                                (formData.requiresAuth === "yes" && (!formData.username || !formData.password))
                                            }
                                            sx={{
                                                marginLeft: "50px",
                                                padding: "22px 63px",
                                                borderRadius: "11px",
                                                fontWeight: "900",
                                                fontSize: "16px",
                                                backgroundColor:
                                                    !formData.websiteName || !formData.websiteUrl || !formData.websitetype ||
                                                        (formData.requiresAuth === "yes" && (!formData.username || !formData.password))
                                                        ? "#ccc"
                                                        : "#ff601f",
                                                color:
                                                    !formData.websiteName || !formData.websiteUrl || !formData.websitetype ||
                                                        (formData.requiresAuth === "yes" && (!formData.username || !formData.password))
                                                        ? "#666"
                                                        : "white",
                                                "&:hover": {
                                                    backgroundColor:
                                                        !formData.websiteName || !formData.websiteUrl || !formData.websitetype ||
                                                            (formData.requiresAuth === "yes" && (!formData.username || !formData.password))
                                                            ? "#ccc"
                                                            : "#ff7d45",
                                                },
                                            }}
                                        >
                                            Next  <FastForwardIcon />
                                        </Button>
                                    </Box>
                                </form>
                            </Box>
                        </Box>

                    )}

                    {activeStep === 2 && (
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                            <Box sx={{ margin: 'auto', backgroundColor: 'white', borderRadius: '10px', padding: '40px 20px' }}>
                                {/* Title Section */}
                                <Typography variant="h6" sx={{ mb: 2, textAlign: 'center', fontWeight: 'bold', fontFamily: 'Space Grotesk' }} className="stepperLocation1">
                                    Verify Your Website Ownership
                                </Typography>

                                {/* List Section */}
                                <List sx={{ mb: 4, padding: 0, display: "grid", justifyContent: "center" }}>

                                    <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }} >
                                        <Typography variant="body1" sx={{ mr: 1, fontWeight: 'bold' }}>
                                            1.
                                        </Typography>
                                        <ListItemText
                                            primary="Sign in to your domain name provider (e.g., godaddy.com or namecheap.com)"
                                            sx={{ textAlign: 'left' }}
                                        />
                                    </ListItem>

                                    <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography variant="body1" sx={{ mr: 1, fontWeight: 'bold' }}>
                                            2.
                                        </Typography>
                                        <ListItemText
                                            primary="Copy the TXT record below into the DNS configuration for your website"
                                            sx={{ textAlign: 'left' }}
                                        />
                                    </ListItem>
                                    <ListItem sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Typography variant="body1" sx={{ mr: 1, fontWeight: 'bold' }}>
                                            3.
                                        </Typography>
                                        <ListItemText primary="Press the Next button below" sx={{ textAlign: 'left' }} />
                                    </ListItem>
                                </List>

                                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
                                    <TextField
                                        label="TXT Record"
                                        variant="outlined"
                                        value="your-txt-record-here" // Value can be dynamic
                                        disabled
                                        fullWidth
                                        sx={{ marginRight: 2 }} // Adding margin to space out the button
                                    />
                                    {/* Copy Button */}
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            // Handle copy logic
                                            navigator.clipboard.writeText("your-txt-record-here");
                                        }}
                                        sx={{
                                            backgroundColor: "#FF601F",
                                            color: "white",
                                            fontWeight: 'bold',
                                            display: 'flex',
                                            alignItems: 'center', // Aligns text and icon vertically
                                            '&:hover': {
                                                backgroundColor: "#FF601F", // Same color on hover
                                            },
                                        }}
                                    >
                                        <Typography sx={{ marginRight: 1 }}>Copy</Typography> {/* Text with margin to space from the icon */}
                                        <FileCopyIcon sx={{ color: 'white' }} /> {/* Icon with white color */}
                                    </Button>
                                </Box>
                                {/* Helper Text */}


                                <Typography sx={{
                                    textAlign: 'center',
                                    fontSize: '13px',
                                    maxWidth: '500px',
                                    width: '100%',
                                    mx: 'auto',
                                    mt: 2,
                                    color: "#ff601f"
                                }}>
                                    Note: Usually after changing TXT Record, it will take effect only after 60 seconds. This token will expire in 7 days.
                                </Typography>
                                <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleBack}
                                        sx={{
                                            padding: "22px 63px",
                                            borderRadius: "11px",
                                            fontWeight: "900",
                                            fontSize: "16px",
                                            backgroundColor: "#ff601f",
                                            color: "white",
                                            "&:hover": { backgroundColor: "#ff7d45" },
                                        }}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNext}

                                        sx={{
                                            marginLeft: "50px",
                                            padding: "22px 63px",
                                            borderRadius: "11px",
                                            fontWeight: "900",
                                            fontSize: "16px",
                                            backgroundColor: "#ff601f",
                                            color: "white",
                                            "&:hover": {
                                                backgroundColor: "#ff601f",
                                            },
                                        }}
                                    >
                                        Next  <FastForwardIcon />
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    )}

                    {activeStep === 3 && (

                        <Box sx={{ display: "flex", justifyContent: "center" }}>


                            <Box sx={{ display: "grid", margin: 'auto', justifyContent: "space-evenly", backgroundColor: 'white', borderRadius: '10px', padding: '40px 20px' }}>
                                <Typography variant="h6" sx={{ mb: 2, textAlign: 'center', fontWeight: 'bold', fontFamily: 'Space Grotesk' }} className="stepperLocation1">
                                    Google Analytics
                                </Typography>
                                <Box sx={{ maxWidth: '600px', mx: 'auto', mb: 4 }}>
                                    {/* Service Account Section */}
                                    <Box sx={{ mb: 3 }}>
                                        <Typography variant="body1" sx={{ mb: 1 }}>
                                            1) Please enter the following service account as user in your analytics account
                                        </Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                            <TextField
                                                label="Service Account"
                                                value="eso-analyticsreport@analytics-for-eso.iam.gserviceaccount.com"
                                                disabled
                                                fullWidth
                                                sx={{ mr: 2 }}
                                            />
                                            <Button
                                                variant="contained"
                                                onClick={() => {
                                                    navigator.clipboard.writeText(
                                                        "eso-analyticsreport@analytics-for-eso.iam.gserviceaccount.com"
                                                    );
                                                }}
                                                sx={{
                                                    backgroundColor: "#FF601F",
                                                    color: 'white',
                                                    fontWeight: 'bold',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    '&:hover': {
                                                        backgroundColor: "#FF601F",
                                                    },
                                                    height: '100%',

                                                }}
                                            >
                                                <Typography sx={{ mr: 1, padding: "8px !important" }}>Copy</Typography>
                                                <FileCopyIcon sx={{ color: 'white' }} />
                                            </Button>
                                        </Box>
                                    </Box>

                                    {/* Google Property ID Section */}
                                    <Box sx={{ mb: 3 }}>
                                        <Typography variant="body1" sx={{ mb: 1 }}>
                                            2) Google Property ID
                                        </Typography>
                                        <Box>
                                            <TextField
                                                id="googlepropertyid"
                                                label="Google Property ID"
                                                value={formData.googlepropertyid}
                                                onChange={(event) =>
                                                    setFormData((prevData) => ({
                                                        ...prevData,
                                                        googlepropertyid: event.target.value,
                                                    }))
                                                }
                                                fullWidth
                                                sx={{
                                                    "& .MuiInputLabel-root": { color: "#000000" },
                                                    "& .MuiOutlinedInput-root": {
                                                        "& fieldset": { borderColor: "#FF601F" },
                                                        "&.Mui-focused fieldset": { borderColor: "#FF601F" },
                                                    },
                                                }}
                                            />
                                            <Typography
                                                sx={{
                                                    textAlign: 'center',
                                                    fontSize: '13px',
                                                    color: 'gray',
                                                    mt: 1, // Spacing above the note
                                                    display: "flex"
                                                }}
                                            >
                                                Note: Provide Viewer Access Only.
                                            </Typography>
                                        </Box>

                                    </Box>
                                </Box>
                                <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleBack}
                                        sx={{
                                            padding: "12px 53px",
                                            borderRadius: "11px",
                                            fontWeight: "900",
                                            fontSize: "16px",
                                            backgroundColor: "#ff601f",
                                            color: "white",
                                            "&:hover": { backgroundColor: "#ff7d45" },
                                        }}
                                    >
                                        Back
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNextRedirect}

                                        sx={{
                                            marginLeft: "50px",
                                            padding: "22px 53px",
                                            borderRadius: "11px",
                                            fontWeight: "900",
                                            fontSize: "16px",
                                            backgroundColor: "#ff601f",
                                            color: "white",
                                            "&:hover": {
                                                backgroundColor: "#ff601f",
                                            },
                                        }}
                                    >
                                        Next  <FastForwardIcon />
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={handleNextRedirect}
                                        className="skipbuttonstepper"
                                        sx={{
                                            marginLeft: "50px",
                                            padding: "12px 53px",
                                            borderRadius: "11px",
                                            fontWeight: "900",
                                            fontSize: "16px",
                                            backgroundColor: "#fff",
                                            border: "3px solid #ff601f",
                                            color: "#ff601f",
                                            "&:hover": {
                                                backgroundColor: "#fff",
                                            },
                                        }}
                                    >
                                        Skip
                                    </Button>
                                </Box>
                            </Box>

                            </Box>

          )} 

                            {activeStep === steps.length && (
                                <Typography variant="h6">All steps completed!</Typography>
                            )}
                        </Box>
      </Box>
        </div>
    );
};

export default StepperFormDup;
