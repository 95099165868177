import React, { useState, useEffect,useContext } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import "./plagiarisamstyle.css";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import fav_icon from "./../Asset/New_Assert/ESO_ICON.png";
import LiquidFillGauge from "react-liquid-gauge";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { Link, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import Tooltip from "@mui/material/Tooltip";
import { Tooltip as MuiTooltip } from "@mui/material";
import LinkIcon from '@mui/icons-material/Link';
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Keywordbar from "./../Rightsidebar/Keywordbar";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Description } from "@mui/icons-material";
import { AppContext } from "../../../Context/Context";
import { Commonfun } from './../Commonfun';
import { timezonechange } from './../Commonfun';
import { timezoneval } from './../Commonfun';

export default function Plagiarisamdetail() {
  const {isMenuExpanded, setIsMenuExpanded} = useContext(AppContext);

  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date());
  const [totalwords, setTotalwords] = useState("");
  const [totalsimilarwords, setTotalsimilarwords] = useState("");
  const [totalpercecntwords, setTotalpercentwords] = useState("");
  const [queryurl, setQueryurl] = useState("");
  const [initialDateval, SetInitialDateval] = useState([]); 
  const [toggle, setToggle] = useState(false);
  const [faqData,setfaqData]=useState("2")
  const esodata = useSelector((state) => state);
  const ApiToken=esodata.apiauthkey.value
  const [autoheightval,SetAutoheightval]=useState(true)

  const [rescardData, setRescardData] = useState([]);
  const [jobDate, setjobDate] = useState("");

  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg,setapierrorMsg]=useState();
  
  const navigate = useNavigate();
  let querystring = useParams();
  let plag_id = querystring.id;
  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
    <svg
      width="120"
      height="100"
      viewBox="0 0 184 152"
      aria-hidden
      focusable="false"
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(24 31.67)">
          <ellipse
            className="ant-empty-img-5"
            cx="67.797"
            cy="106.89"
            rx="67.797"
            ry="12.668"
          />
          <path
            className="ant-empty-img-1"
            d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
          />
          <path
            className="ant-empty-img-2"
            d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
          />
          <path
            className="ant-empty-img-3"
            d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
          />
        </g>
        <path
          className="ant-empty-img-3"
          d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
        />
        <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
          <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
          <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
        </g>
      </g>
    </svg>
    <Box sx={{ mt: 1 }} >No Rows</Box>
  </StyledGridOverlay>

  );
  let detailurl = `${process.env.REACT_APP_DOMAIN}plagiarism-scan-detail/`;

  useEffect(() => {
    callUserdataApi();
    faqdatafun();
  }, []);

  const callUserdataApi = async (jobid = "") => {
    setLoading(true);

    try{

      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}content/v2/plagiarism_detail_report/?user_domain_id=${esodata.domaindata.value.header_selected}&id=${plag_id}`,{
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" ||error?.response?.status==404 ){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
  
      const dataval = datavalue.data;
  
      setTotalwords(dataval.plagiarism_data.response.querywords);
      setTotalsimilarwords(dataval.plagiarism_data.response.allwordsmatched);
      setTotalpercentwords(dataval.plagiarism_data.response.allpercentmatched);
    
      setQueryurl(dataval.plagiarism_data.response.query);
      setjobDate(moment(datavalue.data.job_date).format("MMM DD,YYYY  HH:mm"));
      setCardData(dataval);
      if(dataval.plagiarism_data.response.result && dataval.plagiarism_data.response.result.length>0)
      {
      
        setRescardData(dataval.plagiarism_data.response.result);
        SetAutoheightval(false)
  
        }
        else{
          setRescardData("");
        SetAutoheightval(true)
        }


    }catch(err)
    {
      console.log(err)
    }
 
  
    setLoading(false);
  };
  const rows = rescardData ?? [];
  const columns = [
    {
      field: "url",
      headerName: "Url",
      description:'Matched content from various websites across the overall searches',
      width: 950,
  
      headerAlign: 'center',
      align:'center',
      renderCell: (rescardData) => {

        let firstEightLetters = rescardData.row.url.substring(0, 100);
        let lastThreeLetters = rescardData.row.url.slice(-5); 
        return (
          <>
    
            <span className="full_title"> {rescardData.row.title} </span> <br />{" "}
            <div className="full_title_inr_txt">{rescardData.row.textsnippet }</div>
            <br />
            <MuiTooltip title={rescardData.row.url}>
            <div className="prefered_url_external center">
                <LinkIcon/>
                <a target="_blank" href={rescardData.row.url}>{firstEightLetters+'...'+lastThreeLetters}</a>
                </div>
     
            </MuiTooltip>
           
          </>
        );
      },
    },
    {
      field: "urlwords",
      headerName: "Total Words On Page",
      width: 120,
      headerAlign: 'center',
      align:'center',
      renderCell: (rescardData) => {
        return rescardData.row.urlwords ? rescardData.row.urlwords : "N/A";
      },
    },
    {
      field: "wordsmatched",
      headerName: "Total Matched Words",
      width: 120,
      headerAlign: 'center',
      align:'center',
      description:"Percentage of Words Matched",
      renderCell: (rescardData) => {
        return rescardData.row.wordsmatched
          ? rescardData.row.wordsmatched
          : "N/A";
      },
    },
    {
      field: "percentmatched",
      headerName: "Percentage of Matched Words",
      width: 130,
      headerAlign: 'center',
      align:'center',
      renderCell: (rescardData) => {
        return rescardData.row.percentmatched
          ? rescardData.row.percentmatched
          : "N/A";
      },
    },
    {
      field: "",
      headerName: "Report",
      width: 250,
      sortable: false, 
      headerClassName: 'hide_option',
      headerAlign: 'center',
      align:'center',
      renderCell: (rescardData) => {
        return rescardData.row.wordsmatched != 0 &&
          rescardData.row.wordsmatched != "N/A" ? (
          <a href={detailurl + `${plag_id}` + "/" + `${rescardData.row.index}`}>
            <button className="buttonView">
              Scan Report
              <img
                src={fav_icon}
                className="view_std_img"
                alt="view report icon"
              />
            </button>
          </a>
        ) : (
          "N/A"
        );
      },
    },
  ];
  
  var state = {
    value: totalpercecntwords,
  };
  var startColor = "#6495ed"; // cornflowerblue
  var endColor = "#dc143c"; // crimson
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "#fff";
  const textfillColor = "#0B2033";
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];
  const pdfdown = async () =>{ 

    setLoading(true);
    try{
   
    const response = await fetch(`${process.env.REACT_APP_API_URL}content/v2/plagiarism_detail_report/?user_domain_id=${esodata.domaindata.value.header_selected}&id=${plag_id}&formats=pdf`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                    Authorization: `${ApiToken}`,
                },
            });
            // Check if the request was successful (status code 200)
            if (response.ok) {
                // Convert the response to a blob
                const blob = await response.blob();

                // Create a temporary anchor element
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                //link.download = 'Plagarismdetail.pdf';
                var filedata=await Commonfun("Plagiarism-detail","pdf", ApiToken,esodata.domaindata.value.header_selected);
                // return false;
                link.download = filedata;
                // Trigger a click on the anchor element to start the download
                link.click();

                // Remove the anchor element
                window.URL.revokeObjectURL(link.href);
            } else {
              toast.error("No Data", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
                console.error('Failed to fetch PDF:', response.status, response.statusText);
            }
        } catch (error) {
          toast.error("No Data", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
            console.error('Error during PDF download:', error);
        }
        setLoading(false);

  }
  const csvdown = async () =>{ 
    setLoading(true);
    try{
      const response = await fetch(`${process.env.REACT_APP_API_URL}content/v2/plagiarism_detail_report/?user_domain_id=${esodata.domaindata.value.header_selected}&id=${plag_id}&formats=csv`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                    Authorization: `${ApiToken}`,
                },
            });
            // Check if the request was successful (status code 200)
            if (response.ok) {
                // Convert the response to a blob
                const blob = await response.blob();

                // Create a temporary anchor element
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                var filedata=await Commonfun("Plagiarism-detail","csv", ApiToken,esodata.domaindata.value.header_selected);
                // return false;
                link.download = filedata;
                // Trigger a click on the anchor element to start the download
                link.click();

                // Remove the anchor element
                window.URL.revokeObjectURL(link.href);
            } else {
              toast.error("No Data", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
                console.error('Failed to fetch PDF:', response.status, response.statusText);
            }
        } catch (error) {
          toast.error("No Data", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
            console.error('Error during PDF download:', error);
        }
        setLoading(false);
  }
  const faqdatafun = async(menubutval="") => {
    var datavar=`${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=13`
  
    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" ||error?.response?.status==404 ){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });;
      if (doaminsectiondatavalue.data) {
       
        SetInitialDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) {}
  }
  const handleToggle = () => {
    if(faqData!=1){
      setfaqData(1)
    }
    else{
      setfaqData(2)
    }
        setToggle((pre) => !pre);
  };
  const e = document.getElementById('root');
  e.addEventListener('click', function handleClick(event) {
    console.log(event.target.classList[0])
    if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') || 
    (event.target.classList[0] === 'nav_key_txt') || 
    (event.target.classList[0] === 'cnt_hdr_top_wrap') ||(event.target.classList[0] === 'react-dropdown-select') 
    ||(event.target.classList[0] === 'react-dropdown-select-content') 
    ||(event.target.classList[0] === 'react-dropdown-select-item') 
    || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap')   || (event.target.classList[0] === 'App')  
    || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
     || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row') 
     || (event.target.classList[0] === 'MuiDataGrid-cell')  || (event.target.classList[0] === 'MuiDataGrid-cellContent')
     || (event.target.classList[0] === 'header_inner_btm_wrap')||(event.target.classList[0] === 'over_all_container') 
     ||(event.target.classList[0] === 'input_domain_field') ||(event.target.classList[0] === 'react-dropdown-select-input')
     ||(event.target.classList[0] === 'run_rpt_btn') ||(event.target.classList[0] === 'urlcheck')
     ||(event.target.classList[0] === 'plagiarisam_missing_waring_wrap') 


    ) {
      setfaqData(2)
      setToggle(false)   
    }
});
  return (
    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
    <div className="cnt_audit_outer_wrap plagiarisamdetail_otr_wrap">
         <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
          <Helmet>
                <meta charSet="utf-8" />
                <title>Plagiarism Detail</title>
            </Helmet>
      <div className="cnt_hdr_top_wrap">
        <div className="cnt_hdr_top_lft_wrap">
          <h2>Plagiarism  Checker Detailed Report For</h2>
          <p>{queryurl}</p>
          {/* <p>as of {jobDate}</p> */}
          <p>as of {timezonechange(jobDate)}  <span style={{ fontWeight: 'bold' }}> - {timezoneval()} </span></p>

        </div>
        <div className="cnt_hdr_top_rgt_wrap">
        <div className={`App ${faqData=="1"?'faq_move_class':""}`} >
            <button onClick={handleToggle} className="faq_class"  title="Frequently Asked Question">
            <QuestionMarkIcon />
              
            </button>
           {toggle && <Keywordbar close={() => setToggle(false)} initialDateval={initialDateval} />} 
           <img src={pdficon} alt="pdf icon" onClick={pdfdown}/>
           <img src={csvicon} alt="csv icon" onClick={csvdown} />
          </div>
        
        </div>
      </div>

      <div className="cnt_hdr_content">
        <div className="box_wrap">
          <div>
            <h1></h1>
            <p></p>
          </div>
          <div className="cnt_hdr_content_box_outer">
            <div className="plagiarisam_missing_waring_wrap">
              <p>{jobDate}</p>
              <p>Date of Scan</p>
            </div>
            <div className="plagiarisam_missing_waring_wrap">
              <p>{totalwords}</p>
              <p>Total Words</p>
            </div>
            <div className="plagiarisam_missing_waring_wrap">
              <p>{cardData.total_matching_url}</p>
              <p>Total Matching Urls</p>
            </div>
            <div className="plagiarisam_missing_waring_wrap">
              <p>{totalsimilarwords}</p>
              <p>Total Similarity Word Count</p>
            </div>
            <div className="plagiarisam_missing_waring_wrap plagiarisam_chart_outer">
              <div className="plagiarisam_chart">
                <LiquidFillGauge
                  style={{ margin: "0 auto" }}
                  width={54}
                  height={54}
                  value={state.value}
                  percent="%"
                  textSize={1}
                  textOffsetX={0}
                  textOffsetY={0}
                  riseAnimation
                  waveAnimation
                  waveFrequency={2}
                  waveAmplitude={1}
                  gradient
                  gradientStops={gradientStops}
                  circleStyle={{
                    fill: "#FFFFFF",
                  }}
                  waveStyle={{
                    fill: "#FFFFFF",   
                  }}
                  textStyle={{
                    fill: color("#0b2033").toString(),
                    fontFamily: "Arial",
                  }}
                  waveTextStyle={{
                    fill: color("#0b2033").toString(),
                    fontFamily: "Arial",
                  }}
                />
              </div>
              <p> Similarity Count</p>
            </div>
          </div>
        </div>
      </div>
      <div className="data_table plagirisam_detail" style={{ width: "100%" }}>
        <div style={{ height: 550, width: "100%" }}>
          <DataGrid
            getRowId={(row) => row.index}
            slots={{
              noRowsOverlay: MyCustomNoRowsOverlay,
              toolbar: GridToolbar
            }}
            slotProps={{
              toolbar: {
            
                showQuickFilter: true,
              
              },
            }}
            rows={rows}
            columns={columns}
            sx={{
              "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                py: "8px",
              },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "15px",
              },
              "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                py: "22px",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                whiteSpace: "normal",
                lineHeight: "normal"
              },
             '&, [class^=MuiDataGrid]': { border: 'none' } ,
             ".MuiDataGrid-virtualScroller": {
              height: "auto !important",
              overflowY: "auto",
            },
            "& .MuiDataGrid-cell:first-child": {
              position: "sticky",
              top: "0",
              left: "0",
              paddingLeft: "1.5rem",
              zIndex: 999,
            },
            "& .MuiDataGrid-columnHeader:first-child": {
              position: "sticky",
              top: "0",
              left: "0",
              paddingLeft: "1.5rem",
              border: "none",
              zIndex: 999,
            },
            
            }}
            getRowHeight={() => "auto"}
            autoHeight={autoheightval?true:false}         
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10, 30, 50, 75, 100]}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            loading={loading}
          />
        </div>
        <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
    </div>
  );
}
