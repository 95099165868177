import React from 'react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button } from '@mui/material';
import FastForwardIcon from '@mui/icons-material/FastForward';
import Stepper from './Stepper';
import logoimage from '../NavBar/Asset/New_Assert/ESO_Logo.png'
// import logoimage from './Asset/New_Assert/ESO_Logo.png'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import {  Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function Third() {
  const navigate = useNavigate();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',paddingBottom:'30px',paddingTop:'80px' }} >
             <header className="stepper-header">
        <img className="Eso_logo" src={logoimage} alt="logo" style={{width:"14%",background:"#fff"}} />             
        <Link to="/"> <PowerSettingsNewIcon /></Link>
        
      </header>
            <Stepper />
    
    <div style={{ width: "750px", backgroundColor: "white", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", borderRadius: "8px", padding: "32px" }}>
        <h2 style={{ textAlign: "center", fontSize: "24px", fontFamily: "Space Grotesk", color: "#2d2d2d" }}>Verify Your Website Ownership...</h2>
        <p style={{ textAlign: "center", color: "#4a4a4a", marginTop: "8px" }}>To Establish Your Authority Over The Website</p>
        <ol style={{ color: "#2d2d2d", marginTop: "24px", listStyleType: "decimal", paddingLeft: "20px", lineHeight: "1.6" }}>
            <li>Sign In To Your Domain Name Provider (E.G., Godaddy.Com Or Namecheap.Com)</li>
            <li>Copy The TXT Record Below Into The DNS Configuration For Your Website</li>
            <li>Press The Next Button Below</li>
        </ol>
        <div style={{ display: "flex", marginTop: "16px", alignItems: "center", gap: "16px" }}>
            <input
                type="text"
                value="v=txt_record_example_value"
                readOnly
                style={{ width: "100%", height: "50px", padding: "16px", borderRadius: "8px", backgroundColor: "#e5e5e5", color: "#2d2d2d", border: "none" }}
            />
            <Button sx={{ width: "110px", height: "50px", backgroundColor: "#ff601f", color: "white", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: "8px" }} endIcon={<ContentCopyIcon/>}>
                Copy                
            </Button>
        </div>
        <p style={{ textAlign: "center", marginTop: "16px", color: "#f97316" }}>
            *Note: Usually After Changing TXT Record Will Take Effect Only After 60 Secs This Token Will Expire In 7 Days.
        </p>
        <div style={{display:'flex',justifyContent:'center'}}>
                            <Button sx={{backgroundColor:'#ff601f'}} variant="contained" endIcon={<FastForwardIcon/>}  onClick={()=>{navigate('/fourth')}} >
                                Next                                
                            </Button>
                        </div>
    </div>
    

    </div>
  )
}

export default Third