import React, { useState, useEffect,useRef ,useContext} from "react";
import { styled, useTheme , createTheme} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import Box from "@mui/material/Box";
import "./../style.css";
import { Link, useParams } from "react-router-dom";


import calendarpng from "./../Asset/New_Assert/ESO_Icon/Filter.svg";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import LinkIcon from '@mui/icons-material/Link';
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import fav_icon from "./../Asset/New_Assert/ESO_ICON.png";
import Select from "react-dropdown-select";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { useSelector } from "react-redux";
import DomainAddForm from "./DomainAddForm";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Loader from "../CommonLoader";
import { Tooltip } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import { AppContext } from "../../../Context/Context";
export default function UserDomainsecList() {
  const {isMenuExpanded, setIsMenuExpanded} = useContext(AppContext);

  const theme = createTheme();
  const [cardData, setCardData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState("");
  const [selectval, setSelectval] = useState("");
  const [selectionModel, setSelectionModel] =useState([]);
  const [dateIsOpen, setdateIsOpen] = React.useState(false);
  const [EditBrandTerm,SetEditBrandTerm]=useState('')
  const [EditBrandMatch,SetEditBrandMatch]=useState('')
  const [EditBrandId,SetEditBrandId]=useState('')

  const [EnableKeywordAddForm,SetEnableKeywordAddForm]=useState(false)

  const [CallPreferredList,SetCallPreferredList]=useState(false)
  const [buttnval,setButtnval]=useState("1")
  const [menubutval,setMenubutval]=useState("1")
  const [websiteName,SetWebsiteName] =useState("")
  const [webLink,SetwebLink] =useState("")
  const [webnameError,SetWebnameError]=useState("")
  const [weblinkError,SetweblinkError]=useState("")
  const [pagename,setPagename]=useState("Spell Checker Settings")
  const [selectedRows, setSelectedRows] = useState([]);
  const [deleteId,SetDeleteId]=useState("")

  const esodata = useSelector((state) => state);
  const [faqData,setfaqData]=useState("2")
    const [toggle, setToggle] = useState(false);
    const [initialfaqDateval,SetInitialfaqDateval] = useState([]);
  const ApiToken=esodata.apiauthkey.value
  const [file, setFile] = useState("Choose File");
  const [fileName, setFileName] = useState('Choose File');
  const [uploadedFile, setUploadedFile] = useState({});
  const [error, setError] = useState('');
  const [autoheightval,SetAutoheightval]=useState(true)

  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg,setapierrorMsg]=useState();
  const navigate = useNavigate();
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const [successapiopen, setsuccessapiopen] = React.useState(false);

  const [apisuccessMsg,setapisuccessMsg]=useState();
  var startColor = "#6495ed"; // cornflowerblue
  var endColor = "#dc143c"; // crimson
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "rgb(255,174,31)";
  const textfillColor = "rgb(255,255,255)";
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];

  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </StyledGridOverlay>
  );

  const handleClickOpen =async (e) => {
    SetDeleteId(e.target.id)
    setIsOpen(true);
  };



  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  let subtitle;
  let detailurl = "${process.env.REACT_APP_DOMAIN}"+`edit-domainsec-list/`;
 
  useEffect(() => {
    callUserdataApi();
    faqdatafun()
  }, []);

  const onFileChange = (event) => {
    setLoading(true);
    const selectedFile = event.target.files[0];
    const validExtensions = ['.csv'];

    if (selectedFile) {
      const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
      if (validExtensions.includes(`.${fileExtension}`)) {
        setFile(selectedFile);
        setError('');
        setLoading(false);
      } else {
        setFile(selectedFile);
        setError('Please upload a CSV file');
        setLoading(false);
      }
    }
  };
  const rows = cardData ?? [];
  const callUserdataApi = async (jobid = "") => {
    setLoading(true);
    setCardData([])
    try{
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}settings/spell_check_settings_view/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        console.log(error)
        if(error?.response?.status==599 || error?.response?.status==500 || error?.response?.status==400  || error?.response?.status==404){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK"){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
      if (datavalue?.data?.spell_check_settings && datavalue?.data?.spell_check_settings.length>0 ) {
      
        if (datavalue.data.spell_check_settings) {
          const newData = datavalue.data.spell_check_settings.map((obj, index) => {
            return { ...obj, sid: index + 1 };
          });
          SetAutoheightval(false)
          setCardData(newData);
        }
      }
      else{
        SetAutoheightval(true)
          setCardData("");
          setapiOpen(true);
          setapierrorMsg("Please Add Ignore Words")
      }

    }catch(err){
      console.log(err)
    }
    setLoading(false);
  };
  function openModal() {
    navigate("/add-spellchecker-list")
  }
  const handleDeleteClick = (id) => {
    SetDeleteId(id);
    setIsOpen(true);
  };

  const handleConfirmDelete = () => {
    DeleteCampaignSettings(deleteId);
  };
  const DeleteCampaignSettings = async (id) => {
    setLoading(true);
    setCardData([])
    setIsOpen(false);
    try{
      var delId=id;
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}settings/delete_ignore_words/`,
        {
          user_domain_id:  esodata.domaindata.value.header_selected,
          ignored_word:  delId,
         },
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==500 || error?.response?.status==400  || error?.response?.status==404){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK"){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
    }catch(error){
      setapiOpen(true);
      setapierrorMsg(error?.response?.data?.error)
    }
    setLoading(false);
    callUserdataApi();
  };
  const reportNow = async () => {
    var err=1;
    if(websiteName===""){
      SetWebnameError("Website Name is Required")
      err=2;
    }
    if(webLink===""){
      SetweblinkError("Website Link is Required")
      err=2;
    }
    if(err==2){
      return false;
    }
    else{
      setLoading(true)
      await axios.post("", {
        email: websiteName,
        url: webLink,
       
      })
        .then((response) => {
       
          //redirect logic
          if (response.data.result == 200) {
            closeModal()
          }
        })
        .catch(error => { setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error) })
      setLoading(false)
    }
   
  };
  function closeModal() {
    setSelectval('')
    setIsOpen(false);
  }

  const columns = [
    {
      field: "sid",
      headerName: "#",
      width: 100,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: 'Index of the row',
    },

    {
      field: "ignored_word",
      headerName: "Ignored Words",
      width: 400,
      headerAlign: "center",
      align: "center",
      sortable: true,
      disableClickEventBubbling: true,
      description: 'Website names that have been added for the domain',
    },
    {
      field: "delete",
      headerName: "Delete",
      width: 200,
      headerAlign: "center",
      align: "center",
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: (cardData) => {
        return (
            <button
              className="buttonView trash_icon"
              // onClick={handleClickOpen}
              onClick={() => handleDeleteClick(cardData.row.ignored_word)}
              id={cardData.row.ignored_word}
            >
              <DeleteIcon />
            </button>
        );
      },
     
    },
  ];
  const findObjectById = (array, id) => {
    return array.find(obj => obj.id === id);
  };

  const [CallCampaignTable,SetCallCampaignTable]=useState(false)
  const e = document.getElementById('root');
  e.addEventListener('click', function handleClick(event) {
    console.log(event.target.classList[0])
    if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') || 
    (event.target.classList[0] === 'nav_key_txt') || 
    (event.target.classList[0] === 'cnt_hdr_top_wrap') ||(event.target.classList[0] === 'react-dropdown-select') 
    ||(event.target.classList[0] === 'react-dropdown-select-content') 
    ||(event.target.classList[0] === 'react-dropdown-select-item') 
    || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap')   || (event.target.classList[0] === 'App')  
    || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
     || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row') 
     || (event.target.classList[0] === 'MuiDataGrid-cell')  || (event.target.classList[0] === 'MuiDataGrid-cellContent')
     || (event.target.classList[0] === 'header_inner_btm_wrap')||(event.target.classList[0] === 'over_all_container') 
     ||(event.target.classList[0] === 'input_domain_field') ||(event.target.classList[0] === 'react-dropdown-select-input')
     || (event.target.classList[0] === 'run_rpt_btn')  || (event.target.classList[0] === 'input_col') || (event.target.classList[0] === 'plagarism_analyzer_row')
     || (event.target.classList[0] === 'MuiDataGrid-cell--withRenderer')) {
     
      setfaqData(2)
      setToggle(false)   
    }
  });
  const faqdatafun = async(menubutval="") => {
    var datavar=`${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=48`
  
    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==500 || error?.response?.status==400  || error?.response?.status==404){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK"){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
      if (doaminsectiondatavalue?.data) {
       console.log("domain data",doaminsectiondatavalue?.data?.faqs)
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) {}
  }
  const handleToggle = () => {
    if(faqData!=1){
      setfaqData(1)
    }
    else{
      setfaqData(2)
    }
        setToggle((pre) => !pre);
  };
  const onFileUpload = async (e) => {
    setLoading(true);
    e.preventDefault();
   if(error==''){
    if (file=="Choose File") {
      // setapiOpen(true);
      setLoading(false);
      setError('Please select a file to upload')
      return;
    }
    e.preventDefault();
    const formData = new FormData();
    formData.append('user_domain_id', esodata.domaindata.value.header_selected);
    formData.append('file', file, file.name);
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}settings/ignore_words_bulk_upload/`, 
      formData,
        {
          headers: {
            Authorization: `${ApiToken}`,
            'Content-Type': 'multipart/form-data',
          },
         
        }).catch((error) => {
          setLoading(false);
          if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if(error?.code =="ERR_NETWORK" ||error?.response?.status==404 ){
            navigate('/errorpages')    
          }
          // console.log("kannan",error)
        });
        if(res?.data?.message){
          // window.location.reload();
          setLoading(false);
          setsuccessapiopen(true);
          setapisuccessMsg(res?.data?.message)
          setFile(null);     
          setFile("Choose File");         
        }

    } catch (error) {
      setapiOpen(true);
      setapierrorMsg(error?.response?.data?.error)
    }

   }
   else{
    setLoading(false);
    if (file=="Choose File") {
      // setapiOpen(true);
      setError('Please select a file to upload')
      return;
    }else{
      setError('Please upload a CSV file');
      return false;
    }
   }
   
  };
  const fileInputRef = useRef(null);
  const handleDivClick = (e) => {
    e.preventDefault();
    // Programmatically click the file input
    fileInputRef.current.click();
  };

  const apisuccesshandleClose = () => {
    window.location.reload();
    setsuccessapiopen(false);
  };

  return (
    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
             <Helmet>
                <meta charSet="utf-8" />
                <title>Spell Checker Settings </title>
            </Helmet>
    <div className="cnt_audit_outer_wrap">
      <div  className="cnt_hdr_top_wrap">
        <div className="cnt_hdr_top_lft_wrap">
        <h2> {pagename}</h2>
     
        </div>
        <div className="cnt_hdr_top_rgt_wrap">
        <div className={`App ${faqData=="1"?'faq_move_class':""}`} >
            <button onClick={handleToggle} className="faq_class"  title="Frequently Asked Question">
            <QuestionMarkIcon />
              
            </button>
           {toggle && <Rightsidebar close={() => setToggle(false)} initialfaqDateval={initialfaqDateval} />} 
          </div>
          {/* <img src={pdficon} alt="pdf icon" />
          <img src={csvicon} alt="csv icon" /> */}
        </div>

       
      </div>
    <div className="cnt_hdr_blw_wrap add_keyword_button_wrap domain_wrap">
    <button className="run_rpt_btn rn_rpt_btn" onClick={()=>{openModal()}}>
     Add Ignore Word
    </button>
  
  </div>
  <div className="plagarism_analyzer_row no_pad accessfile">
        
        <div className="input_col">
        <div className="genrate_input_col">
  <form className="fileform" onSubmit={onFileUpload}>
    <div className='upload-container' >
    <label htmlFor="file-input" className="upload-label" onClick={handleDivClick}>Select File</label>
      <input id="file-input"
    type="file"
    className="field-style"
    ref={fileInputRef}
    accept=".csv" onChange={onFileChange} />
     
      {file && <p className="file-info">{file.name}</p>}
    </div>
    <button type="submit" className="blubutton">Upload File</button>
  </form>
  {error && <p style={{color: 'red'}}>{error}</p>}
</div>
        </div>
      </div>

 <div className="data_table brand_list_table campapgin_settings_list_table" style={{ width: "100%" }}>
  <div style={{ height: 550, width: "100%" }}>
    <DataGrid

      getRowId={(row) => row.sid}
      slots={{
                    noRowsOverlay: MyCustomNoRowsOverlay,
                    toolbar: GridToolbar
                  }}
                  slotProps={{
                    toolbar: {
                  
                      showQuickFilter: true,
                    
                    },
                  }}
      rows={rows}
      columns={columns}
      sx={{
        "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
          py: "8px",
        },
        "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
          py: "15px",
        },
        "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
          py: "22px",
        },
        "& .MuiDataGrid-columnHeaderTitle": {
          whiteSpace: "normal",
          lineHeight: "normal",
        },
        "&, [class^=MuiDataGrid]": { border: "none" },
      }}
      getRowHeight={() => "auto"}
      autoHeight={autoheightval?true:false}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 10,
          },
        },
      }}
      loading={loading}
      pageSizeOptions={[10, 30, 50, 75, 100]}
      disableColumnFilter
      disableColumnSelector
      disableDensitySelector
    />
  </div>
</div> 
     
    </div>
    <Dialog
    open={modalIsOpen}
    onClose={closeModal}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    className="competitor_test_filter job_date_filter"
  >
    <DialogTitle>Alert</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-slide-description">
        <div className="cnt_hdr_blw_wrap row">
        
        Are you sure you want to delete this Ignore Words?       
        </div>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <button className="run_rpt_btn rn_rpt_btn" onClick={handleConfirmDelete} style={{cursor:'pointer'}} >
        OK
      </button>
      <button style={{cursor:'pointer'}} onClick={closeModal}>close</button>
    </DialogActions>
  </Dialog>
  <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={successapiopen}
          onClose={apisuccesshandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apisuccessMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apisuccesshandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
    </div>
  

  );
}
