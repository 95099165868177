import React, { useState } from 'react'
import Stepper from './Stepper'
// import '../App.css'
import { Box, Button, InputLabel } from '@mui/material'
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FastForwardIcon from '@mui/icons-material/FastForward';
import logoimage from '../NavBar/Asset/New_Assert/ESO_Logo.png'
// import logoimage from './Asset/New_Assert/ESO_Logo.png'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const currencies = [
    {
        value: 'India',
        label: 'India',
    },
    {
        value: 'Pakistan',
        label: 'Pakistan',
    },
    {
        value: 'Africa',
        label: 'Africa',
    },
    {
        value: 'Japan',
        label: 'Japan',
    },
];

const currencies1 = ["Yes","No"]

function Second() {

    const [selectedCity, setSelectedCity] = useState("");

    const handleChange = (e) => {
        console.log("ddd", e.target.value);
    };

    const navigate = useNavigate();

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingBottom: '30px', paddingTop: '80px' }}>
            <header className="stepper-header">
                <img className="Eso_logo" src={logoimage} alt="logo" style={{ width: "14%", background: "#fff" }} />
                <Link to="/"> <PowerSettingsNewIcon /></Link>

            </header>
            <Stepper />
            <div
                style={{
                    width: '400px',
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                    padding: '18px',
                    margin: '0 auto', // Center the form
                }}
            >
                <h1
                    style={{
                        fontSize: '1.5rem',
                        fontFamily: 'Space Grotesk',
                        color: '#1f1f1f',
                        textAlign: 'center',
                        marginBottom: '8px',
                        padding: '0 16px', // Prevents text from touching the edges
                    }}
                >
                    Details About Your Website
                </h1>
                <p
                    style={{
                        textAlign: 'center',
                        color: '#6b6b6b',
                        marginBottom: '24px',
                        padding: '0 16px', // Prevents text from touching the edges
                        fontFamily: 'Space Grotesk'
                    }}
                >
                    Get To Know The Heart Of Your Website
                </p>

                <form style={{ marginBottom: '20px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '20px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>

                            <InputLabel id="outlined-select-currency-native" >Website Name</InputLabel>
                            <TextField
                                id="outlined-select-currency-native"
                                // label="Website Name"
                                // placeholder="Type here..."
                                // InputLabelProps={{ shrink: true }}

                                sx={{
                                    '& .MuiInputLabel-root': {
                                        color: 'black',
                                        '&.Mui-focused': {
                                            color: 'black',
                                        },
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#ff601f',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#ff601f',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#ff601f',
                                        },
                                        // position: 'relative',
                                        // '&::after': {
                                        //     content: '""',
                                        //     position: 'absolute',
                                        //     left: 95,
                                        //     top: -3,
                                        //     width: '7px',
                                        //     height: '7px',
                                        //     borderRadius: '50%',
                                        //     backgroundColor: '#ff601f',
                                        // },
                                        // '&::before': {
                                        //     content: '""',
                                        //     position: 'absolute',
                                        //     left: 5,
                                        //     top: -3,
                                        //     width: '7px',
                                        //     height: '7px',
                                        //     borderRadius: '50%',
                                        //     backgroundColor: '#ff601f',
                                        // },
                                    },

                                }}
                            >

                            </TextField>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <InputLabel id="outlined-select-currency-native" >Website Link</InputLabel>

                            <TextField
                                id="outlined-select-currency-native"
                                // label="Website Link"
                                // placeholder="Enter URL..."
                                // slotProps={{
                                //     select: {
                                //         native: true,
                                //     },
                                // }}
                                sx={{

                                    '& .MuiInputLabel-root': {
                                        color: 'black',
                                        '&.Mui-focused': {
                                            color: 'black',
                                        },
                                    },

                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#ff601f',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#ff601f',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#ff601f',
                                        },
                                        // position: 'relative',
                                        // '&::after': {
                                        //     content: '""',
                                        //     position: 'absolute',
                                        //     left: 86,
                                        //     top: -3,
                                        //     width: '7px',
                                        //     height: '7px',
                                        //     borderRadius: '50%',
                                        //     backgroundColor: '#ff601f',
                                        // },
                                        // '&::before': {
                                        //     content: '""',
                                        //     position: 'absolute',
                                        //     left: 5,
                                        //     top: -3,
                                        //     width: '7px',
                                        //     height: '7px',
                                        //     borderRadius: '50%',
                                        //     backgroundColor: '#ff601f',
                                        // },
                                    },
                                }}
                            >

                            </TextField>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <InputLabel id="outlined-select-currency-native" >Website Type</InputLabel>

                            <TextField
                                id="outlined-select-currency-native"
                                select
                                // label="Website Type"
                                onChange={(e) => handleChange(e)}
                                // slotProps={{
                                //     select: {
                                //         native: true,
                                //     },
                                // }}

                                sx={{
                                    '& .MuiInputLabel-root': {
                                        color: 'black',
                                        '&.Mui-focused': {
                                            color: 'black',
                                        },
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#ff601f',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#ff601f',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#ff601f',
                                        },
                                        // position: 'relative',
                                        // '&::after': {
                                        //     content: '""',
                                        //     position: 'absolute',
                                        //     left: 90,
                                        //     top: -3,
                                        //     width: '7px',
                                        //     height: '7px',
                                        //     borderRadius: '50%',
                                        //     backgroundColor: '#ff601f',
                                        // },
                                        // '&::before': {
                                        //     content: '""',
                                        //     position: 'absolute',
                                        //     left: 5,
                                        //     top: -3,
                                        //     width: '7px',
                                        //     height: '7px',
                                        //     borderRadius: '50%',
                                        //     backgroundColor: '#ff601f',
                                        // },
                                    },
                                }}

                            >
                                <MenuItem value="" disabled>Select Type</MenuItem>
                                {currencies1.map((option,i) => (
                                    <MenuItem key={i} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                        <div style={{ position: 'relative', width: '100%' }}>
                            <InputLabel id="outlined-select-currency-native" >Does Your Website Need Authentication To Access ?</InputLabel>

                            <TextField
                                id="outlined-select-currency-native"
                                // label="Does Your Website Need Authentication To Access ?"
                                onChange={(e) => handleChange(e)}
                                // InputLabelProps={{ shrink: true }}
                                fullWidth
                                sx={{
                                    '& .MuiInputLabel-root': {
                                        color: 'black',
                                        '&.Mui-focused': {
                                            color: 'black',
                                        },
                                    },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': {
                                            borderColor: '#ff601f',
                                        },
                                        '&:hover fieldset': {
                                            borderColor: '#ff601f',
                                        },
                                        '&.Mui-focused fieldset': {
                                            borderColor: '#ff601f',
                                        },
                                        // position: 'relative',
                                        // '&::after': {
                                        //     content: '""',
                                        //     position: 'absolute',
                                        //     left: 300,
                                        //     top: -3,
                                        //     width: '7px',
                                        //     height: '7px',
                                        //     borderRadius: '50%',
                                        //     backgroundColor: '#ff601f',
                                        // },
                                        // '&::before': {
                                        //     content: '""',
                                        //     position: 'absolute',
                                        //     left: 5,
                                        //     top: -3,
                                        //     width: '7px',
                                        //     height: '7px',
                                        //     borderRadius: '50%',
                                        //     backgroundColor: '#ff601f',
                                        // },
                                    },
                                }}
                                InputProps={{
                                    readOnly: true, // This makes the input field read-only
                                }}
                            >
                                {/* <MenuItem value="" disabled>Select City</MenuItem>
                                {currencies.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                    </MenuItem>
                                ))} */}
                            </TextField>

                            {/* Button container positioned at the bottom */}
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    position: 'absolute',
                                    bottom: '-16px', // Adjust this for the desired distance from the bottom of TextField
                                    left: '50%',
                                    transform: 'translateX(-50%)', // Center the button container horizontally
                                    width: 'auto', // Buttons will automatically adjust to their content size
                                    backgroundColor: 'white',
                                    border: '1px solid #ff601f',
                                    borderRadius: '50px'
                                }}
                            >
                                <Button
                                    sx={{
                                        backgroundColor: '#fff',
                                        boxShadow: 'none',
                                        borderRadius: '50px',
                                        width: 'auto', // Ensures buttons take only the space they need
                                        color: '#ff601f',
                                        '&:hover': { backgroundColor: '#ff601f', color: '#fff' }

                                    }}
                                    variant="contained"
                                >
                                    Yes
                                </Button>
                                <Button
                                    sx={{
                                        backgroundColor: '#fff',
                                        boxShadow: 'none',
                                        borderRadius: '50px',
                                        color: '#ff601f',
                                        width: 'auto', // Ensures buttons take only the space they need
                                        '&:hover': { backgroundColor: '#ff601f', color: '#fff' }
                                    }}
                                    variant="contained"
                                >
                                    No
                                </Button>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '30px' }}>
                            <Button sx={{ backgroundColor: '#ff601f' }} variant="contained" endIcon={<FastForwardIcon />} onClick={() => { navigate('/third') }}>
                                Next
                            </Button>
                        </div>
                    </div>

                </form>
            </div>





        </div>
    )
}

export default Second