import React, { useState, useEffect,useContext } from "react";
import axios from "axios";
import "./spellcheckstyle.css";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Link, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Rightsidebar from "./../Rightsidebar/Rightsidebar";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { AppContext } from "../../../Context/Context";
import { Commonfun } from './../Commonfun';
import { timezonechange } from './../Commonfun';
import { timezoneval } from './../Commonfun';
export default function CodeauditDetail() {
  const {isMenuExpanded, setIsMenuExpanded} = useContext(AppContext);

  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [jobDate, setjobDate] = useState("");
  const [spelldata, setSpelldata] = useState("");
  const [tablecardData, setTableCardData] = useState([]);
  const [faqData,setfaqData]=useState("2")
  const [toggle, setToggle] = useState(false);
  const [initialfaqDateval,SetInitialfaqDateval] = useState([]);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg,setapierrorMsg]=useState();
  
  const navigate = useNavigate();
  let querystring = useParams();
  let spell_audit_id = querystring.detailid;
  let spell_job_id = querystring.jobid;
  
  const esodata = useSelector((state) => state);
  const ApiToken=esodata.apiauthkey.value
  useEffect(() => {
    callUserdataApi();
    faqdatafun();
  }, []);
  const apihandleClose = () => {
    setapiOpen(false);
  };
  const callUserdataApi = async () => {
    setLoading(true);
    const datavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}content/v2/spell_check_detail/?user_domain_id=${esodata.domaindata.value.header_selected}&id=${spell_audit_id}&job_id=${spell_job_id}`,{
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    ).catch((error) => {
      if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
      }
      else if(error?.code =="ERR_NETWORK"){
        navigate('/errorpages')    
      }
      // console.log("kannan",error)
    });
    const date_dataval = datavalue?.data;
    setSpelldata(date_dataval)
    setjobDate(moment(date_dataval.job_date).format("MMMM DD,YYYY HH:mm"));
    const dataval = datavalue?.data?.table_data[0];
    const table_dataval = datavalue?.data?.table_data[0]?.result;
    setCardData(dataval);


    if(table_dataval)
    {
      const newData = table_dataval.map((obj, index) => {
        return { ...obj, sid: index+1 };
      });
  console.log("newdata",newData)
      setTableCardData(newData);
    }
    
  
    setLoading(false);

  };
  const columns = [

    {
      field: "sid",
      headerName: "#",
      width: 70,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description:'Index of the row'
      
    },
 
    {
      field: "misspelled_word",
      headerName: "Misspelled Words",
      width: 150,
      headerAlign: 'center',
      align:'center',
      description:'Misspelled words are present in the webpage URL.'
    },
    {
      field: "",
      headerName: "Ignore",
      width: 200,
      headerAlign: "center",
      sortable: false,
      align: "center",
      disableClickEventBubbling: false,
      renderCell: (tablecardData) => {
        return (
         
          <button id={tablecardData.row.misspelled_word} type="button" class="ignore_button_active" onClick={ignoreFunction}  title={`Clicking the “Ignore” button will ignore this word on this page only. To permanently ignore this word please add it on “Settings -> Spell Checker Settings”.`}>Ignore</button>
        );
      },
    },
    {
      field: "misspelled_words",
      headerName: "Ignore All",
      width: 200,
      headerAlign: "center",
      align: "center",
      sortable:false,
      renderCell: (tablecardData) => {
        return (
          <button
            id={tablecardData.row.misspelled_word}
            type="button"
            className="ignore_button_active"
            onClick={ignoreallFunction}
            title={`Clicking the “Ignore All” button will ignore this word on this report only. To permanently ignore this word please add it on “Settings -> Spell Checker Settings” `}
          >
            Ignore All
          </button>
        );
      },
    },
    {
      field: "suggested_words",
      headerName: "Suggested Words",
      width: 450,
      sortable: false,
      headerAlign: 'center',
      align:'center',
      disableClickEventBubbling: false,
      sortable:true,
      description:'Suggested words for the misspelled words.'
    },

   
  ];
  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));
  const rows = tablecardData ?? [];
  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
    <svg
      width="120"
      height="100"
      viewBox="0 0 184 152"
      aria-hidden
      focusable="false"
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(24 31.67)">
          <ellipse
            className="ant-empty-img-5"
            cx="67.797"
            cy="106.89"
            rx="67.797"
            ry="12.668"
          />
          <path
            className="ant-empty-img-1"
            d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
          />
          <path
            className="ant-empty-img-2"
            d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
          />
          <path
            className="ant-empty-img-3"
            d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
          />
        </g>
        <path
          className="ant-empty-img-3"
          d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
        />
        <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
          <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
          <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
        </g>
      </g>
    </svg>
    <Box sx={{ mt: 1 }} >No Rows</Box>
  </StyledGridOverlay>

  );
  const ignoreFunction =async(e) =>{
    setLoading(true);
    let data_word=e.target.id
const datavalues = await axios.get(
  `${process.env.REACT_APP_API_URL}content/v2/spell_check_detail_ignore/?user_domain_id=${esodata.domaindata.value.header_selected}&id=${spell_audit_id}&job_id=${spell_job_id}&word=${data_word}`,
  {
    headers: {
      Authorization: `${ApiToken}`,
    },
  }).catch((error) => {
    if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
      setapiOpen(true);
      setapierrorMsg(error?.response?.data?.error)
    }
    else if(error?.code =="ERR_NETWORK"){
      navigate('/errorpages')    
    }
    // console.log("kannan",error)
  });
  if(datavalues?.status===200){
    callUserdataApi();
  }
  setLoading(false);
  }
  const ignoreallFunction =async(e) =>{
    setLoading(true);
    let ignoreall_data_word=e.target.id;
    const ignore_datavalues = await axios.get(
      `${process.env.REACT_APP_API_URL}content/v2/spell_check_detail_ignore_all/?user_domain_id=${esodata.domaindata.value.header_selected}&id=${spell_audit_id}&job_id=${spell_job_id}&word=${ignoreall_data_word}`,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK"){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
      if(ignore_datavalues?.status===200){
        callUserdataApi();
      }
      setLoading(false);
  }
  
  function generateRandom() {
    var length = 8,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
}

const pdfdown = async () =>{ 

  setLoading(true);
  try{
    
  const response = await fetch(`${process.env.REACT_APP_API_URL}content/v2/spell_check_detail/?user_domain_id=${esodata.domaindata.value.header_selected}&id=${spell_audit_id}&is_format=pdf`, {
              method: 'GET',
              headers: {
                  'Content-Type': 'application/pdf',
                  Authorization: `${ApiToken}`,
              },
          });
          // Check if the request was successful (status code 200)
          if (response.ok) {
              // Convert the response to a blob
              const blob = await response.blob();

              // Create a temporary anchor element
              const link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              var filedata=await Commonfun("SpellcheckerDetail","pdf", ApiToken,esodata.domaindata.value.header_selected);
              // return false;
              link.download = filedata;
              link.click();
              // Remove the anchor element
              window.URL.revokeObjectURL(link.href);
              return false;
          } else {
            toast.error("No Data", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
              console.error('Failed to fetch PDF:', response.status, response.statusText);
          }
      } catch (error) {
        toast.error("No Data", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
        
          console.error('Error during PDF download:', error);
      }
      setLoading(false);

}
const csvdown = async () =>{ 
  setLoading(true);
  try{
    const response = await fetch(`${process.env.REACT_APP_API_URL}content/v2/spell_check_detail/?user_domain_id=${esodata.domaindata.value.header_selected}&id=${spell_audit_id}&is_format=csv`, {
              method: 'GET',
              headers: {
                  'Content-Type': 'application/csv',
                  Authorization: `${ApiToken}`,
              },
          });
          // Check if the request was successful (status code 200)
          if (response.ok) {
              // Convert the response to a blob
              const blob = await response.blob();

              // Create a temporary anchor element
              const link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              var filedata=await Commonfun("SpellcheckerDetail","csv", ApiToken,esodata.domaindata.value.header_selected);
              link.download = filedata;
              link.click();
              // Remove the anchor element
              window.URL.revokeObjectURL(link.href);
          } else {
            toast.error("No Data", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
              console.error('Failed to fetch PDF:', response.status, response.statusText);
          }
      } catch (error) {
        toast.error("No Data", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
          console.error('Error during PDF download:', error);
      }
      setLoading(false);
}
const faqdatafun = async(menubutval="") => {
  var datavar=`${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=12`

  try {
    const doaminsectiondatavalue = await axios.get(
      datavar,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    ).catch((error) => {
      if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
      }
      else if(error?.code =="ERR_NETWORK"){
        navigate('/errorpages')    
      }
      // console.log("kannan",error)
    });
    if (doaminsectiondatavalue.data) {
     console.log("domain data",doaminsectiondatavalue?.data?.faqs)
      SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
    }
  } catch (err) {}
}

const handleToggle = () => {
  if(faqData!=1){
    setfaqData(1)
  }
  else{
    setfaqData(2)
  }
      setToggle((pre) => !pre);
};
const e = document.getElementById('root');
e.addEventListener('click', function handleClick(event) {
  console.log(event.target.classList[0])
  if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') || 
  (event.target.classList[0] === 'nav_key_txt') || 
  (event.target.classList[0] === 'cnt_hdr_top_wrap') ||(event.target.classList[0] === 'react-dropdown-select') 
  ||(event.target.classList[0] === 'react-dropdown-select-content') 
  ||(event.target.classList[0] === 'react-dropdown-select-item') 
  || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap')   || (event.target.classList[0] === 'App')  
  || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
   || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row') 
   || (event.target.classList[0] === 'MuiDataGrid-cell')  || (event.target.classList[0] === 'MuiDataGrid-cellContent')
   || (event.target.classList[0] === 'header_inner_btm_wrap')||(event.target.classList[0] === 'over_all_container') 
   ||(event.target.classList[0] === 'input_domain_field') ||(event.target.classList[0] === 'react-dropdown-select-input')
   ||(event.target.classList[0] === 'data_table') ||(event.target.classList[0] === 'plagiarisam_missing_waring_wrap_databox')
   ||(event.target.classList[0] === 'MuiDataGrid-footerContainer') ||(event.target.classList[0] === 'ignore_button_active')

  
  ) {
    setfaqData(2)
    setToggle(false)   
  }
});
  return (

    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
       <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
          <Helmet>
                <meta charSet="utf-8" />
                <title>Spell Checker Detail</title>
            </Helmet>
    <div className="cnt_audit_outer_wrap">
      <div className="cnt_hdr_top_wrap spell-detail">
        <div className="box_wrap schema_anaysis_outer_wrap">
          <div className="cnt_hdr_top_lft_wrap">
         <p> <h3>Detailed Spell Checker Report For - {cardData?.link__url} </h3></p>
            {/* <p>as of {jobDate}</p> */}
          <p>as of {timezonechange(jobDate)}  <span style={{ fontWeight: 'bold' }}> - {timezoneval()} </span></p>

          </div>
          <div className="cnt_hdr_top_rgt_wrap">
          <div className={`App ${faqData=="1"?'faq_move_class':""}`} >
            <button onClick={handleToggle} className="faq_class"  title="Frequently Asked Question">
            <QuestionMarkIcon />
              
            </button>
           {toggle && <Rightsidebar close={() => setToggle(false)} initialfaqDateval={initialfaqDateval} />} 
           <img src={pdficon} alt="pdf icon" onClick={pdfdown}/>
           <img src={csvicon} alt="csv icon" onClick={csvdown}  />
          </div>
         
          </div>
        </div>
      </div>
     
      <div className="data_table spell_checker_detail_table" style={{ width: "100%" }}>
      <div class="plagiarisam_missing_waring_wrap_databox">
            <div><b>{cardData?.misspelled_words_count}</b></div>
            <div>Total Misspelled words count</div>
          </div>
        <div style={{ height: 550, width: "100%" }}>
          <DataGrid
            getRowId={(row) => row.sid}
            // getRowId={(row) =>  generateRandom()}
            slots={{
              noRowsOverlay: MyCustomNoRowsOverlay,
              toolbar: GridToolbar
            }}
            slotProps={{
              toolbar: {
            
                showQuickFilter: true,
              
              },
            }}
            rows={rows}
            columns={columns}
            sx={{
              "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                py: "8px",
              },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "15px",
              },
              "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                py: "22px",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                whiteSpace: "normal",
                lineHeight: "normal",
              },
              "&, [class^=MuiDataGrid]": { border: "none" },
              ".MuiDataGrid-virtualScroller": {
                height: "auto !important",
                overflowY: "auto",
              },
              "& .MuiDataGrid-cell:first-child": {
                position: "sticky",
                top: "0",
                left: "0",
                paddingLeft: "1.5rem",
                zIndex: 999,
              },
              "& .MuiDataGrid-columnHeader:first-child": {
                position: "sticky",
                top: "0",
                left: "0",
                paddingLeft: "1.5rem",
                border: "none",
                zIndex: 999,
              },
            }}
            getRowHeight={() => 'auto'}
            autoHeight={false}             
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10, 30, 50, 75, 100]}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            loading={loading}
          />
        </div>
        <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
    </div>
  );
}
