import React, { useState, useEffect,useContext } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { Link, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import { AppContext } from "../../../Context/Context";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { Tooltip  } from "@mui/material";
import LinkIcon from '@mui/icons-material/Link';
export default function Missingimagedetails() {
  const {isMenuExpanded, setIsMenuExpanded} = useContext(AppContext);

  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rescardData, setRescardData] = useState([]);
  const [jobDate, setjobDate] = useState("");
  const esodata = useSelector((state) => state);
  const ApiToken=esodata.apiauthkey.value
  const [autoheightval,SetAutoheightval]=useState(true)
  const [queryurl, Setqueryurl] = useState("");
  const [jobcardData, setjobcardData] = useState([]);

  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg,setapierrorMsg]=useState();
  const navigate = useNavigate();
  let querystring = useParams();
  let plag_id = querystring.id;
  let job_plag_id = querystring.jobid;

  const apihandleClose = () => {
    setapiOpen(false);
  };
  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));
  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
    <svg
      width="120"
      height="100"
      viewBox="0 0 184 152"
      aria-hidden
      focusable="false"
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(24 31.67)">
          <ellipse
            className="ant-empty-img-5"
            cx="67.797"
            cy="106.89"
            rx="67.797"
            ry="12.668"
          />
          <path
            className="ant-empty-img-1"
            d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
          />
          <path
            className="ant-empty-img-2"
            d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
          />
          <path
            className="ant-empty-img-3"
            d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
          />
        </g>
        <path
          className="ant-empty-img-3"
          d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
        />
        <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
          <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
          <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
        </g>
      </g>
    </svg>
    <Box sx={{ mt: 1 }} >No Rows</Box>
  </StyledGridOverlay>

  );
  let detailurl = `${process.env.REACT_APP_API_URL }technical/onpagecrawl/?user_domain_id=${esodata.domaindata.value.header_selected}&field_type=detail_missing_alt&page_url_id=${plag_id}&job_id=${job_plag_id}`;

  useEffect(() => {
    callUserdataApi();
  }, []);
  function generateRandom() {
    var length = 8,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
}
  const callUserdataApi = async (jobid = "") => {
    setLoading(true);
    const datavalue = await axios.get(
      `${detailurl}`,{
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    ).catch((error) => {
      if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
      }
      else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
        navigate('/errorpages')    
      }
      // console.log("kannan",error)
    });

    const dataval = datavalue?.data?.table?.missing_alt;
    if (dataval && dataval.length > 0) {
      const newData = dataval.map((obj, index) => {
        return { ...obj, sid: index + 1 };
      });
      setCardData(newData);
      Setqueryurl(datavalue?.data?.page_url)
      SetAutoheightval(false)
  setjobcardData(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"))

    }
    else{
      setCardData("");
      SetAutoheightval(true)
    }
   

    setLoading(false);
    console.log("missingalt-dataval ",dataval)
  };
  
  const rows = cardData ?? [];
  const columns = [
    {
      field: "sid",
      headerName: "#",
      width: 70,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "images_missing_alt",
      headerName: "Url",
      description:'Image URLs present in the web page',
      width: 750,
      sortable: false,
      headerAlign: 'center',
      align:'false',
      disableClickEventBubbling: true,
      renderCell: (cardData) => {

        // let firstEightLetters = cardData.row.page_url.substring(0, 60);
        // let lastThreeLetters = cardData.row.page_url.slice(-5); 
      
          return (
            <>
            <Tooltip title={cardData.row.images_missing_alt}>
            <div className="prefered_url_external">
                <LinkIcon/>
                <a target="_blank" href={cardData.row.images_missing_alt}>{cardData.row.images_missing_alt}</a>
                </div>
     
            </Tooltip>
               </>
          );
        
      },
    },
  
  ];
  const pdfdown = async () =>{ 

    setLoading(true);
    try{
    const response = await fetch(`${process.env.REACT_APP_API_URL}technical/onpagecrawl/?user_domain_id=${esodata.domaindata.value.header_selected}&field_type=detail_missing_alt&page_url_id=${plag_id}&job_id=${job_plag_id}&is_format=pdf`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                    Authorization: `${ApiToken}`,
                },
            });
            // Check if the request was successful (status code 200)
            if (response.ok) {
                // Convert the response to a blob
                const blob = await response.blob();

                // Create a temporary anchor element
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'onPagecrawl.pdf';

                // Trigger a click on the anchor element to start the download
                link.click();

                // Remove the anchor element
                window.URL.revokeObjectURL(link.href);
            } else {
              toast.error("No Data", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
                console.error('Failed to fetch PDF:', response.status, response.statusText);
            }
        } catch (error) {
          toast.error("No Data", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
            console.error('Error during PDF download:', error);
        }
        setLoading(false);

  }
  const csvdown = async () =>{ 
    setLoading(true);
    try{
    const response = await fetch(`${process.env.REACT_APP_API_URL}technical/onpagecrawl/?user_domain_id=${esodata.domaindata.value.header_selected}&field_type=detail_missing_alt&page_url_id=${plag_id}&job_id=${job_plag_id}&is_format=csv`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                    Authorization: `${ApiToken}`,
                },
            });
            // Check if the request was successful (status code 200)
            if (response.ok) {
                // Convert the response to a blob
                const blob = await response.blob();

                // Create a temporary anchor element
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'onPagecrawl.csv';

                // Trigger a click on the anchor element to start the download
                link.click();

                // Remove the anchor element
                window.URL.revokeObjectURL(link.href);
            } else {
              toast.error("No Data", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
                console.error('Failed to fetch PDF:', response.status, response.statusText);
            }
        } catch (error) {
          toast.error("No Data", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
            console.error('Error during PDF download:', error);
        }
        setLoading(false);
  }
  
  return (
    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
    <div className="cnt_audit_outer_wrap plagiarisamdetail_otr_wrap">
        <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
      <div className="cnt_hdr_top_wrap image-details">
        <div className="cnt_hdr_top_lft_wrap">
          <h2>Missing ALT Attribute         </h2>
          <p>{queryurl}</p>
          <p>  <b>as of {jobcardData} </b></p>
        </div>
        <div className="cnt_hdr_top_rgt_wrap">
        <img src={pdficon} alt="pdf icon" onClick={pdfdown}/>
          <img src={csvicon} alt="csv icon" onClick={csvdown} />
        </div>
      </div>

    
      <div className="data_table" style={{ width: "100%" }}>
        <div style={{ height: 900, width: "100%" }}>
          <DataGrid
            getRowId={(row) =>  generateRandom()}
            slots={{
              noRowsOverlay: MyCustomNoRowsOverlay,
            }}
            rows={rows}
            columns={columns}
            sx={{
              "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                py: "8px",
              },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "15px",
              },
              "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                py: "22px",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                whiteSpace: "normal",
                lineHeight: "normal"
              },
             '&, [class^=MuiDataGrid]': { border: 'none' } ,
             ".MuiDataGrid-virtualScroller": {
              height: "auto !important",
              overflowY: "auto",
            },
            "& .MuiDataGrid-cell:first-child": {
              position: "sticky",
              top: "0",
              left: "0",
              paddingLeft: "1.5rem",
              zIndex: 999,
            },
            "& .MuiDataGrid-columnHeader:first-child": {
              position: "sticky",
              top: "0",
              left: "0",
              paddingLeft: "1.5rem",
              border: "none",
              zIndex: 999,
            },
            }}
            getRowHeight={() => 'auto'}
            autoHeight={autoheightval?true:false}               
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10, 30, 50, 75, 100]}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            loading={loading}
          />
        </div>
      </div>
    </div>
    <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
    </div>
  );
}
