import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import "./Siteauditstyle.css";
import calendarpng from "./../Asset/New_Assert/ESO_Icon/Filter.svg";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import LiquidFillGauge from "react-liquid-gauge";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Select from "react-dropdown-select";
import { useSelector, useDispatch } from "react-redux";
import { GetCompetitorJobIds as GetCompetitorInJobIds } from "../../../Store/CompetitorInJobId";
import {SetredirectValue } from "../../../Store/PageredirectSlice";

import Loader from "../CommonLoader";
import Tooltip from "@mui/material/Tooltip";
import Onpagecrawllist from "../Auditsummary";
import { Link } from "react-router-dom";
import LinkIcon from '@mui/icons-material/Link';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Button from "@mui/material/Button";
import useMediaQuery from '@mui/material/useMediaQuery';

export default function Siteauditlists(props) {
  console.log("kannanval",props)
  const isMobile = useMediaQuery('(max-width:480px)');

  const [cardData, setCardData] = useState([]);
  const [sitecardData, setSitecardData] = useState([]);
  const [internalcardData, setInternalcardData] = useState([]);
  const [seoindexData, setSeoindexData] = useState([]);
  const [seooccurData, setSeooccurData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState("");
  const [selectval, setSelectval] = useState("");
  const [serveritydata, setServeritydata] = useState([]);
  const [value, setValue] = useState("1");
  const [sitechartvalue, setSiteChartvalue] = useState("4");
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [autoheightval,SetAutoheightval]=useState(true)
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg,setapierrorMsg]=useState();

  const [JobId, SetJobId] = useState("");


  const [dEngineoption, SetdEngineoption] = useState({
    completed_at:
      'Select Date',
      id:
      'Select Date',
  });
  const apihandleClose = () => {
    setapiOpen(false);
  };

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </StyledGridOverlay>
  );

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  var startColor = "#6495ed"; // cornflowerblue
  var endColor = "#dc143c"; // crimson
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "#0b2033";
  const textfillColor = "rgb(255,255,255)";
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handlechartChange = (event, newValue) => {
    setSiteChartvalue(newValue);
  };
  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  let subtitle;
  let detailurl = `${process.env.REACT_APP_DOMAIN}plagiarism-detail/`;

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const arr = [];

  const SeoMetricsArray=[
    
    {
    key:'no_index_robot',
    value:'The web pages must be accessible to Search engines for better SEO results. This metric will check if the web page is accessible to search engines'
  },
  {
    key:'missing_canonical',
    value:'Canonical URL informs search engines that a specific URL represents the source of the content for a web page. This metrics will check for a canonical URL or tag on the web page'
  },
  {
    key:'missing_titles',
    value:'The title tag specifies the web page title. This metrics will show the total of webpages which have missing title'
  },
  {
    key:'duplicate_h1_tags',
    value:'H1 tags are used to define important HTML headings. This metrics will show the total of webpages that have multiple H1 tags'
  },
  {
    key:'missing_h1_tags',
    value:'H1 tags are used to define important HTML headings. This metrics will show the total of webpages that have missing H1 tags'
  },
  {
    key:'hreflang',
    value:"Hreflang is an HTML attribute used to specify a web page's language and geographic target. This metrics displays the total count of webpages using the hreflang attribute"
  },
  {
    key:'pagination_prev_or_next',
    value:"This metrics displays the count of Page URLs which have next and previous URLs"
  },
  {
    key:'duplicate_titles',
    value:"The title tag specifies the web page title. This metrics will show the total of webpages which have same title"
  },
  {
    key:'duplicate_descriptions',
    value:"The description tag specifies the description of the web page. This metrics populate the total number of webpages that have the same description"
  },
  {
    key:'missing_descriptions',
    value:"The description tag specifies the description of the web page. This metrics populate the total number of webpages which have missing description"
  },
  {
    key:'missing_alt_tags',
    value:"The ALT tag describes the presence or function of an image on a web page. This metrics will show the total count of images on a web page which has missing ALT tags"
  },
  {
    key:'h1_tags_available',
    value:"H1 tags are used to define important HTML headings. This metrics will show the total of webpages that have an H1 tag"
  },
  {
    key:'images_available',
    value:"Images Available	This metrics provides the total number of images available in the domain"
  },
  {
    key:'server_error_5xx_link',
    value:"5xx status code means that the server failed to complete a request. This metrics displays the count of  web pages which return 5xx status code"
  },
  {
    key:'broken_links_4xx_link',
    value:"4xx status code means that the request for a web page or other resource contains a bad syntax. This metrics displays the count of  web pages which return 4xx status code"
  }


];

function findObjectByKeyValue(array, key, value) {
  return array.find(obj => obj[key] === value);
}
const pageRedirectfun =(e) =>{
  localStorage.setItem("eso_redirect_value", e.currentTarget.id);
  dispatch(SetredirectValue(e.currentTarget.id));
  navigate('/internal-link-checker')

}
const onbuttonClick = (e) =>{
  props.onbutnvaldata(e.currentTarget.id);
  setIsOpen(true);
  // console.log(e.currentTarget.id);
  
  // setTimeout(()=>{
  //   alert("hii")
    // return  <Onpagecrawllist />
// },100)

}
  useEffect(() => {
    CloseModalTrigger();
    dispatch(GetCompetitorInJobIds(esodata.domaindata.value.header_selected));
    callUserdataApi();
  }, []);
  const columns = [
    {
      field: "id",
      headerName: "#",
      description: "Index of the row",
      width: 100,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "seo_metrics",
      headerName: "Seo Metrics",
      description: "On-page SEO elements are present on the website.",
      width: 350,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      sortable: true,
 
      renderCell: (cardData) => {
        var ToolTipTitle='';
        const result = findObjectByKeyValue(SeoMetricsArray, 'key',cardData.row.seo_metrics);
        if(result!==undefined)
        {
          ToolTipTitle=result.value
        }
      if(ToolTipTitle!='')
        {
          if(cardData.row.seo_metrics==="Missing ALT Tags")
          {
            return (
              <Tooltip title={ToolTipTitle}>
              <Link to="/onpage-crawl-data/2/18"> <p className="linkdata_value">{cardData.row.seo_metrics}</p></Link>

              </Tooltip>
            );
          }
          else if(cardData.row.seo_metrics==="Hreflang")
          {
            return (
              <Tooltip title={ToolTipTitle}>
              <Link to="/onpage-crawl-data/2/9"> <p className="linkdata_value"><LinkIcon/>{cardData.row.seo_metrics}</p></Link>
              </Tooltip>
            );
          }
          else if(cardData.row.seo_metrics==="Missing Canonical")
          {
            return (
              <Tooltip title={ToolTipTitle}>
                <Link to="/onpage-crawl-data/2/14"> <p className="linkdata_value"><LinkIcon/>{cardData.row.seo_metrics}</p></Link>
              </Tooltip>
            );
          }
          else if(cardData.row.seo_metrics==="Duplicate Titles")
          {
            return (
              <Tooltip title={ToolTipTitle}>
              <Link to="/onpage-crawl-data/2/4"> <p className="linkdata_value"><LinkIcon/>{cardData.row.seo_metrics}</p></Link>
              </Tooltip>
            );
          }
          else if(cardData.row.seo_metrics==="Duplicate Descriptions")
          {
            return (
              <Tooltip title={ToolTipTitle}>
              <Link to="/onpage-crawl-data/2/5"> <p className="linkdata_value"><LinkIcon/>{cardData.row.seo_metrics}</p></Link>
              </Tooltip>
            );
          }
          else if(cardData.row.seo_metrics==="Missing H1 Tags")
          {
            return (
              <Tooltip title={ToolTipTitle}>
              <Link to="/onpage-crawl-data/2/16"> <p className="linkdata_value"><LinkIcon/>{cardData.row.seo_metrics}</p></Link>
              </Tooltip>
            );
          }
          else if(cardData.row.seo_metrics==="Missing Descriptions")
          {
            return (
              <Tooltip title={ToolTipTitle}>
              <Link to="/onpage-crawl-data/2/15"> <p className="linkdata_value"><LinkIcon/>{cardData.row.seo_metrics}</p></Link>
              </Tooltip>
            );
          }
          else if(cardData.row.seo_metrics==="Multiple H1 Tags")
          {
            return (
              <Tooltip title={ToolTipTitle}>
               <Link to="/onpage-crawl-data/2/19"> <p className="linkdata_value"><LinkIcon/>{cardData.row.seo_metrics}</p></Link>
              </Tooltip>
            );
          }
          else if(cardData.row.seo_metrics==="Duplicate H1 Tags")
          {
            return (
              <Tooltip title={ToolTipTitle}>
              <Link to="/onpage-crawl-data/2/7"> <p className="linkdata_value"><LinkIcon/>{cardData.row.seo_metrics}</p></Link>
              </Tooltip>
            );
          }
          else if(cardData.row.seo_metrics==="Missing Titles")
          {
            return (
              <Tooltip title={ToolTipTitle}>
             <Link to="/onpage-crawl-data/2/2"> <p className="linkdata_value"><LinkIcon/>{cardData.row.seo_metrics}</p></Link>
              </Tooltip>
            );
          }
          else if(cardData.row.seo_metrics==="Mismatch Canonical")
          {
            return (
              <Tooltip title={ToolTipTitle}>
            <Link to="/onpage-crawl-data/2/3"> <p className="linkdata_value"><LinkIcon/>{cardData.row.seo_metrics}</p></Link>
              </Tooltip>
            );
          }
          else if(cardData.row.seo_metrics==="No-index robot")
          {
            return (
              <Tooltip title={ToolTipTitle}>
          <Link to="/onpage-crawl-data/2/17"> <p className="linkdata_value"><LinkIcon/>{cardData.row.seo_metrics}</p></Link>
              </Tooltip>
            );
          }
          else if(cardData.row.seo_metrics==="Pagination Next or Prev")
          {
            return (
              <Tooltip title={ToolTipTitle}>
           <Link to="/onpage-crawl-data/2/8"> <p className="linkdata_value"><LinkIcon/>{cardData.row.seo_metrics}</p></Link>
              </Tooltip>
            );
          }
          else{
            return (
              <Tooltip title={ToolTipTitle}>
              <p>{cardData.row.seo_metrics}</p>
              </Tooltip>
            );
          }
        
        } 
        // else{
        //   console.log("kkkkfsfsfk")
        //     return (
        //       <p>{cardData.row.seo_metrics}</p>
        //     );        
        // }
        if(cardData.row.seo_metrics==="Missing ALT Tags")
        {
          return (
            <Link to="/onpage-crawl-data/2/18"> <p className="linkdata_value"><LinkIcon/>{cardData.row.seo_metrics}</p></Link>
          );
        }
        else if(cardData.row.seo_metrics==="Hreflang")
        {
          return (
           <Link to="/onpage-crawl-data/2/9"> <p className="linkdata_value"><LinkIcon/>{cardData.row.seo_metrics}</p></Link>
          );
        }
        else if(cardData.row.seo_metrics==="Missing Canonical")
        {
          return (
           <Link to="/onpage-crawl-data/2/14"> <p className="linkdata_value"><LinkIcon/>{cardData.row.seo_metrics}</p></Link>
          );
        }
        else if(cardData.row.seo_metrics==="Duplicate Titles")
        {
          return (
           <Link to="/onpage-crawl-data/2/4"> <p className="linkdata_value"><LinkIcon/>{cardData.row.seo_metrics}</p></Link>
          );
        }
        else if(cardData.row.seo_metrics==="Duplicate Descriptions")
        {
          return (
           <Link to="/onpage-crawl-data/2/5"> <p className="linkdata_value"><LinkIcon/>{cardData.row.seo_metrics}</p></Link>
          );
        }
        else if(cardData.row.seo_metrics==="Missing H1 Tags")
        {
          return (
           <Link to="/onpage-crawl-data/2/16"> <p className="linkdata_value"><LinkIcon/>{cardData.row.seo_metrics}</p></Link>
          );
        }
        else if(cardData.row.seo_metrics==="Missing Descriptions")
        {
          return (
           <Link to="/onpage-crawl-data/2/15"> <p className="linkdata_value"><LinkIcon/>{cardData.row.seo_metrics}</p></Link>
          );
        }
        else if(cardData.row.seo_metrics==="Multiple H1 Tags")
        {
          return (
           <Link to="/onpage-crawl-data/2/19"> <p className="linkdata_value"><LinkIcon/>{cardData.row.seo_metrics}</p></Link>
          );
        }
        else if(cardData.row.seo_metrics==="Duplicate H1 Tags")
        {
          return (
           <Link to="/onpage-crawl-data/2/7"> <p className="linkdata_value"><LinkIcon/>{cardData.row.seo_metrics}</p></Link>
          );
        }
        else if(cardData.row.seo_metrics==="Missing Titles")
        {
          return (
           <Link to="/onpage-crawl-data/2/2"> <p className="linkdata_value"><LinkIcon/>{cardData.row.seo_metrics}</p></Link>
          );
        }
        else if(cardData.row.seo_metrics==="Mismatch Canonical")
        {
          return (
           <Link to="/onpage-crawl-data/2/3"> <p className="linkdata_value"><LinkIcon/>{cardData.row.seo_metrics}</p></Link>
          );
        }
        else if(cardData.row.seo_metrics==="No-index robot")
        {
          return (
           <Link to="/onpage-crawl-data/2/17"> <p className="linkdata_value"><LinkIcon/>{cardData.row.seo_metrics}</p></Link>
          );
        }
        else if(cardData.row.seo_metrics==="Pagination Next or Prev")
        {
          return (
           <Link to="/onpage-crawl-data/2/8"> <p className="linkdata_value"><LinkIcon/>{cardData.row.seo_metrics}</p></Link>
          );
        }
        else{
          return (  
            <p>{cardData.row.seo_metrics}</p>
          );
        }
       
      },
    },
    {
      field: "priority",
      headerName: "Priority",
      description: "SEO and site parameters can be prioritized and categorized according to your organization’s marketing strategies in the site audit settings.",
      width: 350,
      headerAlign: "center",
      align: "center",
      sortable: true,

      renderCell: (cardData) => {
        return (
          <p className="button_score_org">{`${cardData?.row?.priority}`}</p>
        );
      },
    },
    {
      field: "occurences",
      headerName: "Occurrence",
      description: "The total number of specific Occurrences present on the website.",
      width: 350,

      headerAlign: "center",
      align: "center",
    },
  ];
  const column1 = [
    {
      field: "id",
      headerName: "#",
      description: "Index of the row",
      width: 100,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "site_metrics",
      headerName: "Site Metrics",
      description: "Website metrics are present on the website.",
      width: 350,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      sortable: true,
      renderCell: (cardData) => {

        var ToolTipTitle='';

        const result = findObjectByKeyValue(SeoMetricsArray, 'key',cardData.row.site_metrics);

        if(result!==undefined)
        {
          ToolTipTitle=result.value
        }    

        if(ToolTipTitle!='')
        {
          if(cardData.row.site_metrics==="Images Available")
          {
            return (
              <Tooltip title={ToolTipTitle}>

              <Link to="/onpage-crawl-data/2/6"> <p className="linkdata_value"><LinkIcon/>{cardData.row.site_metrics}</p></Link>
              </Tooltip>
            );
          }
          else if(cardData.row.site_metrics==="H1 Tags Available")
          {
            return (
              <Tooltip title={ToolTipTitle}>
              <Link to="/onpage-crawl-data/2/20"> <p className="linkdata_value"> <LinkIcon/>{cardData.row.site_metrics}</p></Link>
              </Tooltip>
            );
          }
          else{
            return (
              <Tooltip title={ToolTipTitle}>
               <p className="linkdata_value">{cardData.row.site_metrics}</p>
              </Tooltip>
            );
          }
        }
        else{
          if(cardData.row.site_metrics==="Images Available")
          {
            return (
              <Link to="/onpage-crawl-data/2/6"> <p className="linkdata_value"> <LinkIcon/>{cardData.row.site_metrics}</p></Link>
            );
          }
          else if(cardData.row.site_metrics==="H1 Tags Available")
          {
            return (
              <Link to="/onpage-crawl-data/2/20"> <p className="linkdata_value"> <LinkIcon/>{cardData.row.site_metrics}</p></Link>
            );
          }
          else{
            <p className="linkdata_value">{cardData.row.site_metrics}</p>
          }


        }
       
      },
    
    },
    // {
    //   field: "priority",
    //   headerName: "Priority",
    //   description: "SEO and site parameters can be prioritized and categorized according to your organization’s marketing strategies in the site audit settings.",
    //   width: 350,
    //   headerAlign: "center",
    //   align: "center",
    //   sortable: true,

    //   renderCell: (cardData) => {
    //     return (
    //       <p className="button_score_org">{`${cardData?.row?.priority}`}</p>
    //     );
    //   },
    // },
    {
      field: "occurences",
      headerName: "Occurrence",
      description: "The total number of specific occurences present on the website.",
      width: 350,
      headerAlign: "center",
      align: "center",
    },
  ];
  const column2 = [
    {
      field: "id",
      headerName: "#",
      description: "Index of the row",
      width: 100,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "internal_links",
      headerName: "Internal Metrics",
      description: "It displays server error links (5xx) and broken links (4xx) on the website.",
      width: 350,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      sortable: true,
 
      renderCell: (cardData) => {

        var ToolTipTitle='';

        const result = findObjectByKeyValue(SeoMetricsArray, 'key',cardData.row.internal_links);

        if(result!==undefined)
        {
          ToolTipTitle=result.value
        }

     

        if(ToolTipTitle!='')
        {
          if(cardData.row.internal_links==="Broken Links 4xx Link")
          {
          return (
            <Tooltip title={ToolTipTitle}>
              <p className="linkdata_value"><LinkIcon/>{cardData.row.internal_links}</p>
            </Tooltip>
          );
          }
          else if(cardData.row.internal_links==="Server Error 5xx Link"){
            return (
              <Tooltip title={ToolTipTitle}>
                <p className="linkdata_value"><LinkIcon/>{cardData.row.internal_links}</p>
              </Tooltip>
            );
          }
        }
        
        else{
          if(cardData.row.internal_links==="Broken Links 4xx Link")
          {
          return (
            <p className="linkdata_value" onClick={pageRedirectfun} id="1"><LinkIcon/>{cardData.row.internal_links}</p>
          );
          }
          else if(cardData.row.internal_links==="Server Error 5xx Link")
          {
            return (
              <p className="linkdata_value" onClick={pageRedirectfun} id="2"><LinkIcon/>{cardData.row.internal_links}</p>
            );
            }

        }
       
      },
    },
    {
      field: "priority",
      headerName: "Priority",
      description: "SEO and site parameters can be prioritized and categorized according to your organization’s marketing strategies in the site audit settings.",
      width: 350,
      headerAlign: "center",
      align: "center",
      sortable: true,

      renderCell: (cardData) => {
        return (
          <p className="button_score_org">{`${cardData?.row?.priority}`}</p>
        );
      },
    },
    {
      field: "occurences",
      headerName: "Occurrence",
      description: "The total number of specific occurences present on the website.",
      width: 350,
      headerAlign: "center",
      align: "center",
    },
  ];
  const rows = cardData ?? [];
  const rows1 = sitecardData ?? [];
  const rows2 = internalcardData ?? [];
  const callUserdataApi = async (jobid = "") => {
    setLoading(true);
    
    const datavalue = await axios
      .get(
        `${process.env.REACT_APP_API_URL}technical/siteaudit/?user_domain_id=${esodata.domaindata.value.header_selected}&domain_section_id=${esodata.sectiondomaindata.value}&job_id=${JobId}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      )
      .catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==500 || error?.response?.status==400  || error?.response?.status==404){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK"){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
    if (jobidData === "") {
      const completevalues = await axios
        .get(
          `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?job_type=site_audit&user_domain_id=${esodata.domaindata.value.header_selected}&domain_section_id=${esodata.sectiondomaindata.value}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        )
        .catch((error) => {
          if(error?.response?.status==599 || error?.response?.status==500 || error?.response?.status==400  || error?.response?.status==404){
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
          }
          else if(error?.code =="ERR_NETWORK"){
            navigate('/errorpages')    
          }
          // console.log("kannan",error)
        });
        if(completevalues?.data?.jobs.length>0)
        {
          setcompletedate(completevalues?.data?.jobs);
     
          // SetdEngineoption(completevalues?.data?.jobs[0])
        }
 
        
    }
    const chartvalue = await axios
      .get(
        `${process.env.REACT_APP_API_URL}technical/siteaudit/graph/?user_domain_id=${esodata.domaindata.value.header_selected}&domain_section_id=${esodata.sectiondomaindata.value}&job_id=${JobId}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      )
      .catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==500 || error?.response?.status==400  || error?.response?.status==404){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK"){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
if(datavalue?.data){
  const dataval = datavalue?.data?.seo_metrics_list;
    const dataval1 = datavalue?.data?.site_metrics_list;
    const dataval2 = datavalue?.data?.internal_links_list;
    const chartval = chartvalue?.data?.issue_graph;
    const severity_dataarrvals = [];
    severity_dataarrvals[0] = chartvalue?.data?.high_serverity_graph;
    severity_dataarrvals[1] = chartvalue?.data?.medium_severity_graph;
    severity_dataarrvals[2] = chartvalue?.data?.low_severity_graph;
    severity_dataarrvals[3] = chartvalue?.data?.high_count;
    severity_dataarrvals[4] = chartvalue?.data?.medium_count;
    severity_dataarrvals[5] = chartvalue?.data?.low_count;
    setServeritydata(severity_dataarrvals);
    const seoindex = chartval?.map(function (val, index) {
      return val.seo_metrics;
    });
    const seooccurence = chartval?.map(function (val, index) {
      return val.occurences;
    });
    setSeoindexData(seoindex);
    setSeooccurData(seooccurence);
    
   
    if (dataval) {
      const newData = dataval.map((obj, index) => {
        return { ...obj, id: index + 1 };
      });

      setCardData(newData);
      props.setJobdate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"))
      setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY HH:mm"));
    }

    if (dataval1) {
      const newData1 = dataval1.map((obj, index) => {
        return { ...obj, id: index + 1 };
      });
      if (dataval1 && dataval1.length>0) {
      setSitecardData(newData1);
      SetAutoheightval(false)
      }
      else{
        setSitecardData("");
        SetAutoheightval(true)
      }
    }

    if (dataval2) {
      const newData2 = dataval2.map((obj, index) => {
        return { ...obj, id: index + 1 };
      });

      setInternalcardData(newData2);
    }

    setLoading(false);
  };

}
    
  const barChartData = {
    labels: seoindexData,
    datasets: [
      {
        label: "Occurrence",
        data: seooccurData,
        barPercentage: 0.1,
        categoryPercentage: 4,
        borderWidth: 0,
        backgroundColor: "#FE6020",
      },
    ],
  };
  const reportNow = async () => {
    setLoading(true);
    var errs = 1;
    let errors = {};

    if (selectval === "") {
      errors.dateerror = "This field is required.";
      errs = 2;
    } else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }
    if (errs === 2) {
      return setValidation(errors);
    }
    setjobidData(selectval[0].id);
    closeModal();
    callUserdataApi(selectval[0].id);
  };
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  //filter Add start
  
  const CloseModalTrigger = () => {
    SetJobId("");
    props.setIsOpen(false);
  };

  const JdomainvaldataChange = (value) => {
    SetJobId(value[0].value);
  };

  const FetchCompetitorList = () => {
    var errs = 1;
    let errors = {};

    if (JobId === "") {
      errors.dateerror = "This field is required.";
      errs = 2;
    } else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }
    if (errs === 2) {
      return setValidation(errors);
    }

    if (JobId !== "") {
      console.log("react val", props.setJobdatesval)
      callUserdataApi();
      SetJobId("");
      props.setIsOpen(false);
      if (props.setJobdatesval) 
      props.setJobdatesval(JobId);
    }
  };

  //filter add end
  

  return (
    <>
      <Helmet>
                <meta charSet="utf-8" />
                <title>Site Audit</title>
            </Helmet>
      {/* {
      loading===true ?   <Loader/> : null
    } */}
      <p className=" dummysiteheader" >
        {/* as of {jobDate} */}
      </p>

      <div className="tabs_graph_outer_wrap">
        <TabContext value={sitechartvalue}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handlechartChange}
              aria-label="lab API tabs example"
            >
              <Tooltip title="Clicking on this option displays an issue-based chart for the SEO elements">
                <Tab
                  className="tabbutton"
                  id={sitechartvalue === "4" ? "activetabj" : ""}
                  label="By Issue"
                  value="4"
                />
              </Tooltip>

              <Tooltip title="Clicking on this option, the current Severity based percentage pie chart comparison will be displayed">
                <Tab
                  className="tabbutton"
                  id={sitechartvalue === "5" ? "activetabj" : ""}
                  label="By Severity"
                  value="5"
                />
              </Tooltip>
            </TabList>
          </Box>
          <TabPanel value="4">
            <div className="site_regress_chart_info">
              {/* <p>Site Audit Summary by Issues</p> */}
              <div className="site_Chart_info_inner">
                <Bar
                  type="bar"
                  width={130}
                  height={isMobile ? 150 : 50}
                  data={barChartData}
                  options={{
                    responsive: true,
                    plugins: {
                      // 'legend' now within object 'plugins {}'
                      legend: {
                        display: false,
                      },
                      title: {
                        display: true,
                        text: "Site Audit Summary by Issues",
                        color: "#0b2033",
                        font: {
                          size: 18,
                          family:'Poppins',
                        },
                      },
                    },
                    scales: {
                      y: {
                        // not 'yAxes: [{' anymore (not an array anymore)
                        ticks: {
                          suggestedMin: 0,
                          stacked: true,
                          color: "#0b2033", // not 'fontColor:' anymore
                          // fontSize: 18,
                          font: {
                            size: 14, // 'size' now within object 'font {}'
                            family:'Poppins',
                          },
                          beginAtZero: true,
                        },
                      },
                      x: {
                        // not 'xAxes: [{' anymore (not an array anymore)
                        ticks: {
                          color: "#0b2033", // not 'fontColor:' anymore
                          //fontSize: 14,
                          font: {
                            size: 12, // 'size' now within object 'font {}'
                            family:'Poppins',
                          },
                          beginAtZero: true,
                        },
                      },
                    },
                  }}
                />
                <p>SEO Metrics</p>
              </div>
            </div>
          </TabPanel>
          <TabPanel value="5">
            <div className="site_regress_chart_info">
              {/* <p>Site Audit Summary by Issues</p> */}
              <div className="site_Chart_info_inner">
                <p>Site Audit Summary by Severity</p>
                <div className="siteaudit_chart_outer_container">
                <div className="siteaudit_missing_waring_wrap siteaudit_chart_outer">
                  <div className="siteaudit_chart">
                    <LiquidFillGauge
                      style={{ margin: "0 auto" }}
                      width={184}
                      height={100}
                      value={serveritydata[0]}
                      percent="%"
                      textSize={1}
                      textOffsetX={0}
                      textOffsetY={0}
                      riseAnimation
                      waveAnimation
                      waveFrequency={2}
                      waveAmplitude={1}
                      gradient
                      gradientStops={gradientStops}
                      circleStyle={{
                        fill: textfillColor,
                      }}
                      waveStyle={{
                        fill: fillColor,
                      }}
                      textStyle={{
                        fill: color("#FFFFFF").toString(),
                        fontFamily: "Arial",
                      }}
                      waveTextStyle={{
                        fill: color("#fff").toString(),
                        fontFamily: "Arial",
                      }}
                    />
                  </div>
                  <p> High Impact Issue Occurrences - {serveritydata[3]}</p>
                </div>
                <div className="siteaudit_missing_waring_wrap siteaudit_chart_outer">
                  <div className="siteaudit_chart">
                    <LiquidFillGauge
                      style={{ margin: "0 auto" }}
                      width={184}
                      height={100}
                      value={serveritydata[1]}
                      percent="%"
                      textSize={1}
                      textOffsetX={0}
                      textOffsetY={0}
                      riseAnimation
                      waveAnimation
                      waveFrequency={2}
                      waveAmplitude={1}
                      gradient
                      gradientStops={gradientStops}
                      circleStyle={{
                        fill: textfillColor,
                      }}
                      waveStyle={{
                        fill: fillColor,
                      }}
                      textStyle={{
                        fill: color("#FFFFFF").toString(),
                        fontFamily: "Arial",
                      }}
                      waveTextStyle={{
                        fill: color("#fff").toString(),
                        fontFamily: "Arial",
                      }}
                    />
                  </div>
                  <p>Medium Impact Issue Occurrences - {serveritydata[4]}</p>
                </div>
                <div className="siteaudit_missing_waring_wrap siteaudit_chart_outer">
                  <div className="siteaudit_chart">
                    <LiquidFillGauge
                      style={{ margin: "0 auto" }}
                      width={184}
                      height={100}
                      value={serveritydata[2]}
                      percent="%"
                      textSize={1}
                      textOffsetX={0}
                      textOffsetY={0}
                      riseAnimation
                      waveAnimation
                      waveFrequency={2}
                      waveAmplitude={1}
                      gradient
                      gradientStops={gradientStops}
                      circleStyle={{
                        fill: textfillColor,
                      }}
                      waveStyle={{
                        fill: fillColor,
                      }}
                      textStyle={{
                        fill: color("#FFFFFF").toString(),
                        fontFamily: "Arial",
                      }}
                      waveTextStyle={{
                        fill: color("#fff").toString(),
                        fontFamily: "Arial",
                      }}
                    />
                  </div>
                  <p>Low Impact Issue Occurrences - {serveritydata[5]}</p>
                </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </TabContext>
      </div>
      <div className="tabs_outer_wrap tabs_outer_wrap_tab_btm_rem">
        <TabContext value={value} >
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tooltip title="On-page SEO elements present in the website">
                <Tab
                  label="SEO Metrics"
                  id={value === "1" ? "activetabj" : ""}
                  value="1"
                />
              </Tooltip>

              <Tooltip title="Website Metrics present in the website">
                <Tab
                  label="Site Metrics"
                  id={value === "2" ? "activetabj" : ""}
                  value="2"
                />
              </Tooltip>

              <Tooltip title="It displays Server Error Links (5xx) and Broken Links (4xx) in the website">
                <Tab
                  label="Internal Links"
                  id={value === "3" ? "activetabj" : ""}
                  value="3"
                />
              </Tooltip>
            </TabList>
          </Box>
          <TabPanel value="1" >
            <div className="data_table" style={{ width: "100%" }}>
              <div style={{  width: "100%" }}>
                <DataGrid
                  sx={{
                    "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                      py: "8px",
                    },
                    "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                      py: "15px",
                    },
                    "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell":
                      {
                        py: "22px",
                      },
                    "& .MuiDataGrid-columnHeaderTitle": {
                      whiteSpace: "normal",
                      lineHeight: "normal",
                    },
                    "&, [class^=MuiDataGrid]": { border: "none" },
                    ".MuiDataGrid-virtualScroller": {
                      height: "auto !important",
                      overflowY: "auto",
                    },
                    "& .MuiDataGrid-cell:first-child": {
                      position: "sticky",
                      top: "0",
                      left: "0",
                      paddingLeft: "1.5rem",
                      zIndex: 999,
                    },
                    "& .MuiDataGrid-columnHeader:first-child": {
                      position: "sticky",
                      top: "0",
                      left: "0",
                      paddingLeft: "1.5rem",
                      border: "none",
                      zIndex: 999,
                    },
                  }}
                  autoHeight={autoheightval?true:false} 
                  getRowHeight={() => "auto"}
                  getRowId={(row) => row.seo_metrics}
                  slots={{
                    noRowsOverlay: MyCustomNoRowsOverlay,
                    toolbar: GridToolbar
                  }}
                  slotProps={{
                    toolbar: {
                  
                      showQuickFilter: true,
                    
                    },
                  }}
                  rows={rows}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10, 30, 50, 75, 100]}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel value="2">
            <div className="data_table" style={{ width: "100%" }}>
              <div style={{ height: 750, width: "100%" }}>
                <DataGrid
                  sx={{
                    "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                      py: "8px",
                    },
                    "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                      py: "15px",
                    },
                    "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell":
                      {
                        py: "22px",
                      },
                    "& .MuiDataGrid-columnHeaderTitle": {
                      whiteSpace: "normal",
                      lineHeight: "normal",
                    },
                    "&, [class^=MuiDataGrid]": { border: "none" },
                  }}
                  autoHeight
                  getRowHeight={() => "auto"}
                  getRowId={(row) => row.site_metrics}
                  slots={{
                    noRowsOverlay: MyCustomNoRowsOverlay,
                    toolbar: GridToolbar
                  }}
                  slotProps={{
                    toolbar: {
                  
                      showQuickFilter: true,
                    
                    },
                  }}
                  rows={rows1}
                  columns={column1}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  pageSizeOptions={[5, 10, 25]}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel value="3">
            <div className="data_table" style={{ width: "100%" }}>
              <div style={{ height: 750, width: "100%" }}>
                <DataGrid
                className=""
                  sx={{
                    "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                      py: "8px",
                    },
                    "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                      py: "15px",
                    },
                    "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell":
                      {
                        py: "22px",
                      },
                    "& .MuiDataGrid-columnHeaderTitle": {
                      whiteSpace: "normal",
                      lineHeight: "normal",
                    },
                    "&, [class^=MuiDataGrid]": { border: "none" },
                  }}
                  autoHeight
                  getRowHeight={() => "auto"}
                  getRowId={(row) => row.internal_links}
                  slots={{
                    noRowsOverlay: MyCustomNoRowsOverlay,
                    toolbar: GridToolbar
                  }}
                  slotProps={{
                    toolbar: {
                  
                      showQuickFilter: true,
                    
                    },
                  }}
                  rows={rows2}
                  columns={column2}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  pageSizeOptions={[5, 10, 25]}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                />
              </div>
            </div>
          </TabPanel>
        </TabContext>
      </div>

      <Dialog
        open={props.modalIsOpen}
        onClose={CloseModalTrigger}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="competitor_test_filter job_date_filter"
      >
        <DialogTitle>{"Filter By"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="cnt_hdr_blw_wrap row">
              <div>
                <div className="">
                  <div>
                    <span>Completed Date</span>
                    <Select
                    values={[dEngineoption]}
                    options={completedate}
                    labelField="completed_at"
                    valueField="id"
                    onChange={(values) => {SetJobId(values[0]?.id)
                      SetdEngineoption({
                        completed_at:
                          values[0]?.completed_at,
                          id:
                          values[0]?.id,
                      });                    
                    }}
                
                    />
                  </div>
                </div>

                <div className="col">
                  <span className="text-error">{validation.dateerror}</span>
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            className="run_rpt_btn rn_rpt_btn"
            onClick={FetchCompetitorList}
            style={{ cursor: "pointer" }}
          >
            Submit
          </button>
          <button style={{ cursor: "pointer" }} onClick={CloseModalTrigger}>
            close
          </button>
        </DialogActions>
        <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      </Dialog>
      <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
    </>
  );
}
