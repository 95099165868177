import React from 'react';
import axios from 'axios';
import moment from "moment";
import moments from 'moment-timezone';
export async function Commonfun(filename, type, ApiToken, domainid) {
  try {
   
    const apicalldata = await apicallfun(ApiToken, domainid);
    const now = new Date();
    const day = now.getDate();
    const month = now.getMonth() + 1;
    const year = now.getFullYear();
    const hours = now.getHours().toString().padStart(2, '0');
    const minutes = now.getMinutes().toString().padStart(2, '0');
    const seconds = now.getSeconds().toString().padStart(2, '0');

    return `${filename}-${apicalldata}-${day}-${month}-${year}-${hours}-${minutes}-${seconds}.${type}`;
  } catch (error) {
    console.error("Error in Commonfun:", error);
    throw error; // Re-throw the error to be handled upstream
  }
}
export function timezoneval  () {
  return moment.tz.guess();
}

export  function timezonechange(codejobDate){
  try{
    console.log("kannan",codejobDate)
     var tzs = timezoneval();
       var timeVal = moment.utc(codejobDate).tz(tzs).format("MMMM DD, YYYY HH:mm");
      //  console.log("kannan",timeVal)
       return timeVal;
  }
  catch (error) {
    console.error("Error in apicallfun:", error);
    throw error; // Re-throw the error to be handled upstream
  }
}
export async function apicallfun(ApiToken, domainid) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}domain/get_domain/?user_domain_id=${domainid}`,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    );

    if (response.data && response.data.queryset_values.length > 0) {
      const replacedString = response.data.queryset_values[0].domain__name.replace(/\s+/g, '-');
      console.log(replacedString);
      return replacedString;
    } else {
      throw new Error("No data found or invalid response format");
    }
  } catch (error) {
    console.error("Error in apicallfun:", error);
    throw error; // Re-throw the error to be handled upstream
  }
}
