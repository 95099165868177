import React, { useEffect, useState } from 'react';
// import '../App.css';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';


const steps = ['USER DATA', 'DOMAIN DATA', 'DOMAIN OWNERSHIP VERIFICATION','GOOGLE ANALYTICS'];

function Stepper({selectedCity}) {

    const navigate = useNavigate();
    const [first, setfirst] = useState(1)
    
    const location = useLocation();
    const progressValue = first; 
  
  const circleStyle = (stepIndex) => ({
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    backgroundColor: stepIndex <= progressValue ? '#ff601f' : '#ffd0bc', // Green if step is completed, red otherwise
    color: 'black',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '16px',
    textAlign: 'center',
    fontWeight:'bold'
  });


  useEffect(() => {
    if(location.pathname === '/second'){
        setfirst(2)
    }else if(location.pathname === '/third'){
      setfirst(3)
  }else if(location.pathname === '/fourth'){
    setfirst(4)
}
    
    
  }, [])
  

  return (
    <div style={{ textAlign: 'center',width:'100%' ,backgroundColor:'rgba(255, 255, 255, 0.1)',marginBottom:'30px'}}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', padding: '20px' }}>
        {
          steps.map((txt, i) => (
            <div key={i} style={{ display: 'flex', flexDirection: 'column',justifyContent:'center', alignItems: 'center' }}>
              <div style={circleStyle(i+1)}>0{i + 1}</div>
            </div>
          ))
        }
      </div>

      <progress value={progressValue} max={steps.length} style={{ width: '80%'}} />

      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', padding: '20px 0px' }}>
        {
          steps.map((txt, i) => (
            <div key={i} style={{ display: 'flex', flexDirection: 'column',justifyContent:'center', alignItems: 'center' }}>
              <Box sx={{  color: '#333',textWrap:'wrap',width:{md:'200px'},fontWeight:{md:'bold'},fontSize:{xs:'10px',md:'16px'} }}>{txt}</Box>  {/* Text below the circle */}
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default Stepper