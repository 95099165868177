import React, { useState } from 'react'
import Stepper from './Stepper'
// import '../App.css'
import { Box, Button, InputLabel } from '@mui/material'
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FastForwardIcon from '@mui/icons-material/FastForward';
import { useNavigate } from 'react-router-dom';

import logoimage from '../NavBar/Asset/New_Assert/ESO_Logo.png'
// import logoimage from './Asset/New_Assert/ESO_Logo.png'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { Link } from 'react-router-dom';
const currencies = [
    {
        value: 'India',
        label: 'India',
    },
    {
        value: 'Pakistan',
        label: 'Pakistan',
    },
    {
        value: 'Africa',
        label: 'Africa',
    },
    {
        value: 'Japan',
        label: 'Japan',
    },
];

function First() {

    const [selectedCity, setSelectedCity] = useState("");


    const navigate = useNavigate();

    const handleChange = (e) => {
        console.log("ddd", e.target.value);
    };

    const handleChange1 = (e) => {
        e.preventDefault();
        console.log("ddd");

        navigate('/second', { state: { data: selectedCity } });

    };




    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', paddingBottom: '30px', paddingTop: '80px' }}>
            <header className="stepper-header">
                <img className="Eso_logo" src={logoimage} alt="logo" style={{ width: "14%", background: "#fff" }} />
                <Link to="/"> <PowerSettingsNewIcon /></Link>

            </header>
            <Stepper />
            <div
                style={{
                    width: '400px',
                    backgroundColor: 'white',
                    borderRadius: '8px',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                    padding: '32px',
                    margin: '0 auto', // Center the form
                }}
            >
                <h1
                    style={{
                        fontSize: '1.5rem',
                        fontFamily: 'Space Grotesk',
                        color: '#1f1f1f',
                        textAlign: 'center',
                        marginBottom: '8px',
                        padding: '0 16px', // Prevents text from touching the edges
                    }}
                >
                    Please Share Your Location Details
                </h1>
                <p
                    style={{
                        textAlign: 'center',
                        color: '#6b6b6b',
                        marginBottom: '24px',
                        padding: '0 16px', // Prevents text from touching the edges
                        fontFamily: 'Space Grotesk'
                    }}
                >
                    This Helps Us Customize Your Experience
                </p>

                <form style={{ marginBottom: '20px' }} onSubmit={handleChange1}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '20px' }}>
                        <div style={{ display: 'flex', flexDirection: 'column'}}>
                        <InputLabel id="outlined-select-currency-native" >Country</InputLabel>
                        <TextField
                            id="outlined-select-currency-native"
                            select
                            // label="Country"
                            // InputLabelProps={{ shrink: true }}

                            sx={{
                                '& .MuiInputLabel-root': {
                                    color: 'black',
                                    '&.Mui-focused': {
                                        color: 'black',
                                    },
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#ff601f',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#ff601f',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#ff601f',
                                    },
                                    // position: 'relative',
                                    // '&::after': {
                                    //     content: '""',
                                    //     position: 'absolute',
                                    //     left: 58,
                                    //     top: -3,
                                    //     width: '7px',
                                    //     height: '7px',
                                    //     borderRadius: '50%',
                                    //     backgroundColor: '#ff601f',
                                    // },
                                    // '&::before': {
                                    //     content: '""',
                                    //     position: 'absolute',
                                    //     left: 5,
                                    //     top: -3,
                                    //     width: '7px',
                                    //     height: '7px',
                                    //     borderRadius: '50%',
                                    //     backgroundColor: '#ff601f',
                                    // },
                                },

                            }}
                        >


                            <MenuItem value="" disabled>
                                Select Country
                            </MenuItem>

                            {currencies.map((option) => (
                                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                            ))}
                        </TextField>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column'}}> 
                        <InputLabel id="outlined-select-currency-native" >State</InputLabel>
                        <TextField
                            id="outlined-select-currency-native"
                            select
                            sx={{

                                '& .MuiInputLabel-root': {
                                    color: 'black',
                                    '&.Mui-focused': {
                                        color: 'black',
                                    },
                                },

                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#ff601f',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#ff601f',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#ff601f',
                                    },
                                    // position: 'relative',
                                    // '&::after': {
                                    //     content: '""',
                                    //     position: 'absolute',
                                    //     left: 45,
                                    //     top: -3,
                                    //     width: '7px',
                                    //     height: '7px',
                                    //     borderRadius: '50%',
                                    //     backgroundColor: '#ff601f',
                                    // },
                                    // '&::before': {
                                    //     content: '""',
                                    //     position: 'absolute',
                                    //     left: 5,
                                    //     top: -3,
                                    //     width: '7px',
                                    //     height: '7px',
                                    //     borderRadius: '50%',
                                    //     backgroundColor: '#ff601f',
                                    // },
                                },
                            }}
                        >
                            <MenuItem value="" disabled>
                                Select State
                            </MenuItem>

                            {currencies.map((option) => (
                                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                            ))}
                        </TextField>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column'}}>
                        <InputLabel id="outlined-select-currency-native" >City</InputLabel>

                        <TextField
                            id="outlined-select-currency-native"
                            select
                            // label="City"
                            onChange={(e) => handleChange(e)}
                            

                            sx={{
                                '& .MuiInputLabel-root': {
                                    color: 'black',
                                    '&.Mui-focused': {
                                        color: 'black',
                                    },
                                },
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: '#ff601f',
                                    },
                                    '&:hover fieldset': {
                                        borderColor: '#ff601f',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#ff601f',
                                    },
                                    // position: 'relative',
                                    // '&::after': {
                                    //     content: '""',
                                    //     position: 'absolute',
                                    //     left: 37,
                                    //     top: -3,
                                    //     width: '7px',
                                    //     height: '7px',
                                    //     borderRadius: '50%',
                                    //     backgroundColor: '#ff601f',
                                    // },
                                    // '&::before': {
                                    //     content: '""',
                                    //     position: 'absolute',
                                    //     left: 5,
                                    //     top: -3,
                                    //     width: '7px',
                                    //     height: '7px',
                                    //     borderRadius: '50%',
                                    //     backgroundColor: '#ff601f',
                                    // },
                                },
                            }}

                        >
                            <MenuItem value="" disabled>
                                Select City
                            </MenuItem>
                            {currencies.map((option) => (
                                <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                            ))}
                        </TextField>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button sx={{ backgroundColor: '#ff601f' }} variant="contained" endIcon={<FastForwardIcon />} type='sumbit' >
                                Next
                            </Button>
                        </div>
                    </div>

                </form>
            </div>





        </div>
    )
}

export default First