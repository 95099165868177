import React, { useState, useEffect ,useContext} from "react";
import axios from "axios";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Link, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { styled, useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { Tooltip  } from "@mui/material";
import LinkIcon from '@mui/icons-material/Link';
import { ToastContainer, toast } from 'react-toastify';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Keywordbar from "./../Rightsidebar/Keywordbar";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../Context/Context";
export default function InternallinkSource() {
  const {isMenuExpanded, setIsMenuExpanded} = useContext(AppContext);

  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [faqData,setfaqData]=useState("2")
  const [initialDateval, SetInitialDateval] = useState([]); 
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg,setapierrorMsg]=useState();
  const navigate = useNavigate();

  const [tablecardData, setTableCardData] = useState([]);
  const [LinkUrl,SetLinkUrl]=useState('')
  let querystring = useParams();
  const [autoheightval,SetAutoheightval]=useState(true)


  let spell_job_id = querystring.jobid;
  let data_job_id = querystring.datajobid

  
  const esodata = useSelector((state) => state);
  const ApiToken=esodata.apiauthkey.value
  useEffect(() => {
    callUserdataApi();
    faqdatafun();
  }, []);
  const callUserdataApi = async () => {
    setLoading(true);

    try{

      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}technical/get_source_links/?link_id=${spell_job_id}&job_id=${data_job_id}`,{
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" || error?.response?.status==404 ){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
  
      SetLinkUrl(datavalue?.data?.link_url)
  
  
     
  
      const dataval = datavalue?.data?.source_links[0];
  
      var CardArray=[];
  
      if(dataval && dataval.length>0)
      {
        SetAutoheightval(false);
          dataval.forEach((element,index) => {
              CardArray.push({sid:index+1,url:element}) 
          });
          setCardData(CardArray);
      }
      else{
        setCardData("");
        SetAutoheightval(true);
      }
  
  
      
  

    
    
  

    }catch(err)
    {
      console.log('err',err)
    }

 

    
  
    setLoading(false);

  };
  const columns = [

    {
      field: "sid",
      headerName: "#",
      width: 100,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      
    },
 
    {
      field: "url",
      headerName: "Source Url",
      width: 900,
      headerAlign: 'center',
      align:'center',
      renderCell: (cardData) => {

        // let firstEightLetters = cardData.row.url.substring(0, 200);
        // let lastThreeLetters = cardData.row.url.slice(-5); 
      
          return (
            <>
            <Tooltip title={cardData.row.url}>
            <div className="prefered_url_external">
                <LinkIcon/>
                <a target="_blank" href={cardData.row.url}>{cardData.row.url}</a>
                </div>
     
            </Tooltip>
               </>
          );
        
      },
    }
 

   
  ];
  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));
  const rows = cardData ?? [];
  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
    <svg
      width="120"
      height="100"
      viewBox="0 0 184 152"
      aria-hidden
      focusable="false"
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(24 31.67)">
          <ellipse
            className="ant-empty-img-5"
            cx="67.797"
            cy="106.89"
            rx="67.797"
            ry="12.668"
          />
          <path
            className="ant-empty-img-1"
            d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
          />
          <path
            className="ant-empty-img-2"
            d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
          />
          <path
            className="ant-empty-img-3"
            d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
          />
        </g>
        <path
          className="ant-empty-img-3"
          d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
        />
        <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
          <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
          <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
        </g>
      </g>
    </svg>
    <Box sx={{ mt: 1 }} >No Rows</Box>
  </StyledGridOverlay>

  );

 
  
  function generateRandom() {
    var length = 8,
        charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
        retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
}
const pdfdown = async () =>{ 

  setLoading(true);
  try{
  const response = await fetch(`${process.env.REACT_APP_API_URL}technical/get_source_links/?link_id=${spell_job_id}&job_id=${data_job_id}&formats=pdf`, {
              method: 'GET',
              headers: {
                  'Content-Type': 'application/pdf',
                  Authorization: `${ApiToken}`,
              },
          });
          // Check if the request was successful (status code 200)
          if (response.ok) {
              // Convert the response to a blob
              const blob = await response.blob();

              // Create a temporary anchor element
              const link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = 'Internallinksource.pdf';

              // Trigger a click on the anchor element to start the download
              link.click();

              // Remove the anchor element
              window.URL.revokeObjectURL(link.href);
          } else {
            toast.error("No Data", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
              console.error('Failed to fetch PDF:', response.status, response.statusText);
          }
      } catch (error) {
        toast.error("No Data", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
        
          console.error('Error during PDF download:', error);
      }
      setLoading(false);

}
const csvdown = async () =>{ 
  setLoading(true);
  try{
    const response = await fetch(`${process.env.REACT_APP_API_URL}technical/get_source_links/?link_id=${spell_job_id}&job_id=${data_job_id}&formats=csv`, {
              method: 'GET',
              headers: {
                  'Content-Type': 'application/pdf',
                  Authorization: `${ApiToken}`,
              },
          });
          // Check if the request was successful (status code 200)
          if (response.ok) {
              // Convert the response to a blob
              const blob = await response.blob();

              // Create a temporary anchor element
              const link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = 'Internallinksource.csv';

              // Trigger a click on the anchor element to start the download
              link.click();

              // Remove the anchor element
              window.URL.revokeObjectURL(link.href);
          } else {
            toast.error("No Data", {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              });
              console.error('Failed to fetch PDF:', response.status, response.statusText);
          }
      } catch (error) {
        toast.error("No Data", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
          console.error('Error during PDF download:', error);
      }
      setLoading(false);
}
const e = document.getElementById('root');
e.addEventListener('click', function handleClick(event) {
  console.log(event.target.classList[0])
  if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') || 
  (event.target.classList[0] === 'nav_key_txt') || 
  (event.target.classList[0] === 'cnt_hdr_top_wrap') ||(event.target.classList[0] === 'react-dropdown-select') 
  ||(event.target.classList[0] === 'react-dropdown-select-content') 
  ||(event.target.classList[0] === 'react-dropdown-select-item') 
  || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap')   || (event.target.classList[0] === 'App')  
  || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
   || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row') 
   || (event.target.classList[0] === 'MuiDataGrid-cell')  || (event.target.classList[0] === 'MuiDataGrid-cellContent')
   || (event.target.classList[0] === 'header_inner_btm_wrap')||(event.target.classList[0] === 'over_all_container') 
   ||(event.target.classList[0] === 'input_domain_field') ||(event.target.classList[0] === 'react-dropdown-select-input')
   ||(event.target.classList[0] === 'run_rpt_btn') ||(event.target.classList[0] === 'urlcheck') 
   ||(event.target.classList[0] === 'link_cnt_hdr_blw_wrap') ||(event.target.classList[0] === 'MuiDataGrid-columnHeaderTitle') 
   ||(event.target.classList[0] === 'cols') ||(event.target.classList[0] === 'card_num_class') 
   ||(event.target.classList[0] === 'content_col') || (event.target.classList[0] ==='link_rn_rpt_btn') 
   || (event.target.classList[0] ==='css-b5h07o') || (event.target.classList[0] ==='site_regress_chart_info') 
    || (event.target.classList[0] ==='linechartdesign')|| (event.target.classList[0] ==='apexcharts-legend-series') 
  ) {
    setfaqData(2)
    setToggle(false)   
  }
});
const faqdatafun = async(menubutval="") => {
  var datavar=`${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=22`
  try {
    const doaminsectiondatavalue = await axios.get(
      datavar,
      {
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    ).catch((error) => {
      if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
      }
      else if(error?.code =="ERR_NETWORK" || error?.response?.status==404 ){
        navigate('/errorpages')    
      }
      // console.log("kannan",error)
    });
    if (doaminsectiondatavalue?.data) {
     
      SetInitialDateval(doaminsectiondatavalue?.data?.faqs)
    }
  } catch (err) {}
}
const handleToggle = () => {
  if(faqData!=1){
    setfaqData(1)
  }
  else{
    setfaqData(2)
  }
      setToggle((pre) => !pre);
};
const apihandleClose = () => {
  setapiOpen(false);
};
  return (

    <div className={`over_all_container ${isMenuExpanded ? "blur overflow-hidden" : ""}`}>
         <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
    <div className="cnt_audit_outer_wrap">
      <div className="cnt_hdr_top_wrap">
        <div className="box_wrap schema_anaysis_outer_wrap">
          <div className="cnt_hdr_top_lft_wrap">
            <h2>Internal source links for </h2>
            <p>{LinkUrl}</p>
          </div>
          <div className="cnt_hdr_top_rgt_wrap int-src-check">
          <div className={`App ${faqData=="1"?'faq_move_class':""}`} >
            <button onClick={handleToggle} className="faq_class"  title="Frequently Asked Question">
            <QuestionMarkIcon />
              
            </button>
           {toggle && <Keywordbar close={() => setToggle(false)} initialDateval={initialDateval} />} 
           <img src={pdficon} alt="pdf icon" onClick={pdfdown}/>
           <img src={csvicon} alt="csv icon" onClick={csvdown} />
          </div>
         
          </div>
        </div>
      </div>
    
      <div className="data_table internal_source_table" style={{ width: "100%" }}>
        <div style={{ height: 550, width: "100%" }}>
          <DataGrid
           getRowId={(row) => row.sid}
            slots={{
              noRowsOverlay: MyCustomNoRowsOverlay,
              toolbar: GridToolbar
            }}
            slotProps={{
              toolbar: {
            
                showQuickFilter: true,
              
              },
            }}
            rows={rows}
            columns={columns}
            sx={{
              "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                py: "8px",
              },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "15px",
              },
              "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                py: "22px",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                whiteSpace: "normal",
                lineHeight: "normal"
              },
             '&, [class^=MuiDataGrid]': { border: 'none' },
             ".MuiDataGrid-virtualScroller": {
              height: "auto !important",
              overflowY: "auto",
            },
            "& .MuiDataGrid-cell:first-child": {
              position: "sticky",
              top: "0",
              left: "0",
              paddingLeft: "1.5rem",
              zIndex: 999,
            },
            "& .MuiDataGrid-columnHeader:first-child": {
              position: "sticky",
              top: "0",
              left: "0",
              paddingLeft: "1.5rem",
              border: "none",
              zIndex: 999,
            },
            }}
            getRowHeight={() => 'auto'}
            autoHeight={autoheightval?true:false}               
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            pageSizeOptions={[10, 30, 50, 75, 100]}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            loading={loading}
          />
        </div>
      </div>
      <Dialog
          open={apiopen}
          onClose={apihandleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle>{"Alert!! "}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {apierrorMsg}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={apihandleClose}>Close</Button>
          </DialogActions>
        </Dialog>
    </div>
    </div>
  );
}
