import { Button } from '@mui/material'
import React from 'react'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Stepper from './Stepper';
import FastForwardIcon from '@mui/icons-material/FastForward';
import logoimage from '../NavBar/Asset/New_Assert/ESO_Logo.png'
// import logoimage from './Asset/New_Assert/ESO_Logo.png'
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import {  Link } from 'react-router-dom';


function Fourth() {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',paddingBottom:'30px',paddingTop:'80px' }} >
             <header className="stepper-header">
        <img className="Eso_logo" src={logoimage} alt="logo" style={{width:"14%",background:"#fff"}} />             
        <Link to="/"> <PowerSettingsNewIcon /></Link>
        
      </header>
            <Stepper />
            <div style={{ width: "700px", backgroundColor: "white", padding: "24px", borderRadius: "8px", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}>
                <h1 style={{ fontSize: "24px", fontFamily: "Space Grotesk", textAlign: "center", color: "#2D2D2D" }}>Google Analytics</h1>
                <p style={{ fontSize: "16px", textAlign: "center", color: "#4A4A4A" }}>
                    To Establish Your Authority Over The Website
                </p>

                <div style={{ marginTop: "24px" }}>
                    <label style={{ display: "block", fontSize: "14px", color: "#2D2D2D", marginBottom: "8px" }}>
                        1. Please Enter The Following Service Account As User In Your Analytics Account
                    </label>
                    <div style={{ display: "flex" }}>
                        <input
                            type="text"
                            value=""
                            readOnly
                            style={{ width: "100%", border: "1px solid #1D4ED8", borderRadius: "8px", padding: "12px", backgroundColor: "#E0F2FE", color: "#2D2D2D", fontSize: "15px" }}
                        />
                        <Button sx={{ marginLeft: "8px", width: "100px", height: "50px", backgroundColor: "#ff601f", color: "#E0F2FE", borderRadius: "8px", display: "flex", justifyContent: "center", alignItems: "center" }} endIcon={<ContentCopyIcon />}>
                            Copy

                        </Button>
                    </div>
                </div>

                <div style={{ marginTop: "24px" }}>
                    <label style={{ display: "block", fontSize: "14px", color: "#2D2D2D", marginBottom: "8px" }}>2. Google Property ID:</label>
                    <input
                        type="text"
                        style={{ width: "100%", border: "1px solid #1D4ED8", borderRadius: "8px", padding: "12px", backgroundColor: "#E0F2FE", color: "#2D2D2D", fontSize: "15px" }}
                    />
                </div>

                <p style={{ fontSize: "12px", color: "#F97316", marginTop: "8px" }}>
                    *Note: Provide Viewer Access Only
                </p>

                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "24px" }}>
                    <Button sx={{ minWidth: "100px", height: "50px", backgroundColor: "#ff601f", color: "#E0F2FE", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "15px", borderRadius: "9999px" }} endIcon={<FastForwardIcon/>}>
                        Next
                    </Button>
                    <Button sx={{ marginLeft: "16px", minWidth: "100px", height: "50px", border: "1px solid #ff601f", color: "#ff601f", display: "flex", alignItems: "center", justifyContent: "center", fontSize: "15px", borderRadius: "9999px" }} endIcon={<FastForwardIcon/>} >
                        Skip
                    </Button>
                </div>
            </div>


        </div>
    )
}

export default Fourth

